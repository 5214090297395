import { golfCoursePropertiesDetails } from '@marriott/mi-rnb-graphql';

export const golfCourseLocationsMock = {
  data: {
    searchPropertiesByLocation: {
      total: 83,
      edges: [
        {
          node: {
            id: 'BHMHV',
            basicInformation: {
              name: 'Renaissance Birmingham Ross Bridge Golf Resort & Spa',
              descriptions: [
                {
                  text: 'Experience the allure of Renaissance Birmingham Ross Bridge Golf Resort and Spa. With a scenic location near Hoover Alabama our hotel in Birmingham AL ensures you are never far from things to see and do. On site you will find the fifth-longest golf course in the world at 8,191 yards and 18 holes as well as beautiful ballroom space and a full-service spa, The Spa at Ross Bridge. Following an exciting day, retreat to one of our stylish rooms and suites each equipped with elegant furnishings, luxurious bedding and a private balcony boasting astonishing views of the Alabama hills. After a relaxing evening, wake up with an appetite for savory dishes from any of our upscale restaurants and bars including Brocks, The Clubhouse Restaurant and JTs Lounge. When you are not indulging, release some of your energy at our 24-hour fitness center. We also feature sparkling indoor and outdoor pools so you can make a splash every season of the year. An unforgettable stay awaits at Renaissance Birmingham Ross Bridge Resort.',
                },
                {
                  text: 'Experience luxurious comfort at Renaissance Birmingham Ross Bridge Golf Resort & Spa. The stylish escape features four restaurants and spacious rooms and suites with private balconies, as well as a world-famous golf course and world-class spa.',
                },
                {
                  text: 'Experience luxurious comfort at Renaissance Birmingham Ross Bridge Golf Resort & Spa. The stylish escape features four restaurants and spacious rooms and suites with private balconies, as well as a world-famous golf course and world-class spa.',
                },
                {
                  text: 'Renaissance Birmingham Ross Bridge Golf Resort & Spa is located off Ross Bridge Parkway with access to local attractions such as Sloss Furnaces, Red Mountain Park, and Regions Field. Birmingham-Shuttlesworth International Airport is 16.9 miles away.',
                },
                {
                  text: 'Renaissance Birmingham Ross Bridge Golf Resort & Spa is located off Ross Bridge Parkway with access to local attractions such as Sloss Furnaces, Red Mountain Park, and Regions Field. Birmingham-Shuttlesworth International Airport is 16.9 miles away.',
                },
                {
                  text: 'Enjoy a sophisticated stay at our hotel in Birmingham, AL.',
                },
                {
                  text: 'Discover a private oasis in our hotel rooms in Birmingham',
                },
                {
                  text: 'Stylish resort hotel with on-site restaurants and golf course near Red Mountain Park.',
                },
              ],
              brand: {
                name: 'Renaissance Hotels',
                id: 'BR',
              },
              websiteUrl:
                'https://www.marriott.com/en-us/hotels/bhmhv-renaissance-birmingham-ross-bridge-golf-resort-and-spa/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Alabama',
                },
                city: 'Birmingham',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Drone view of hotel exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-hotel-exterior-64676-03757:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Hotel in Birmingham Alabama Exterior',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BHMHV/bhmhv-exterior-0047-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Birmingham Hotel Front Desk',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BHMHV/bhmhv-desk-0048-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'A hotel frontdesk with some plants ',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-front-desk-28455-01537:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BHMHV/bhmhv-lobby-0093-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Birmingham resort terrace fire pits',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BHMHV/bhmhv-pits-0049-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Exterior',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BHMHV/bhmhv-exterior-0092-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Drone view of hotel exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-hotel-exterior-49017-50517:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Drone Shot of hotel exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-hotel-exterior-39932-38052:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Drone view of resort',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-hotel-exterior-59141-46341:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'lobby seating area',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-lobby--2-37400-33550:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Drone view of hotel exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-hotel-exterior-64801-89418:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby seating area',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-lobby-40866-10614:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Upper level lobby seating area',
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/br-bhmhv-upper-level-lobby-13519-93220:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'drone view of resort with pools',
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/br-bhmhv-hotel-drone-view-26716-92952:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby ',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/br-bhmhv-lobby-57513-71750:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Exterior',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BHMHV/bhmhv-exterior-0091-hor-clsc.jpg',
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'CTDCA',
            basicInformation: {
              name: 'JW Marriott Desert Springs Resort & Spa',
              descriptions: [
                {
                  text: 'Experience the beauty of the desert at JW Marriott Desert Springs Resort & Spa. This luxury resort features personalized service, versatile venues, magnificent mountain and lake views, and family-friendly amenities. From the moment you arrive on the lush resort grounds you will feel a sense of relaxation. Bask in our modern hotel rooms and suites with private balconies, hardwood floors, indulgent bathrooms with marble tile, and lush bedding. Take a dip in one of the outdoor pools, test your swing on our scenic, championship golf courses or our world-renowned tennis and pickleball courts. Rejuvenate yourself at the day spa featuring an original coed Wellness Lounge and satisfy your cravings at one of our signature restaurants. From unforgettable weddings to innovative corporate seminars, our resort offers more than 234,000 sq. ft. of indoor and outdoor event spaces. The resort is minutes from Acrisure Arena, The Living Desert Zoo & Garden, Coachella Valley Music & Arts Festival, and Indian Wells Tennis Garden.',
                },
                {
                  text: 'Discover the desert oasis of JW Marriott Desert Springs Resort & Spa. This luxury hotel offers redesigned accommodations, championship golf courses, tennis courts, a spa, outdoor pools and a gym, as well as delicious dining and sophisticated venues.',
                },
                {
                  text: 'Discover the desert oasis of JW Marriott Desert Springs Resort & Spa. This luxury hotel offers redesigned accommodations, championship golf courses, tennis courts, a spa, outdoor pools and a gym, as well as delicious dining and sophisticated venues.',
                },
                {
                  text: 'Located in the heart of Palm Desert, California, JW Marriott Desert Springs Resort & Spa gives hotel guests easy access to the Coachella Valley Music Festival, events in Indian Wells and the shops, galleries and restaurants of downtown Palm Springs.',
                },
                {
                  text: 'Located in the heart of Palm Desert, California, JW Marriott Desert Springs Resort & Spa gives hotel guests easy access to the Coachella Valley Music Festival, events in Indian Wells and the shops, galleries and restaurants of downtown Palm Springs.',
                },
                {
                  text: 'Daily Self Parking |Daily Fitness Classes for 2 guests |Daily Golf Bag Storage and Range Access for 2 guests |Daily Miniature Golf for 2 guests |Daily Lawn Games including Badminton, Basketball, Croquet, Soccer, Frisbee and Horseshoes |Daily 1hr Hard Court & Racquet Rentals for 2 guests |Daily Enhanced Hi-Speed Internet Access',
                },
                {
                  text: 'Discover luxury at our Palm Desert, CA resort',
                },
                {
                  text: 'Experience the beauty of Palm Desert from our luxury hotel',
                },
                {
                  text: 'Luxury Palm Desert resort with outdoor pools, golf courses, a spa, dining and upscale event venues.',
                },
              ],
              brand: {
                name: 'JW Marriott',
                id: 'JW',
              },
              websiteUrl:
                'https://www.marriott.com/en-us/hotels/ctdca-jw-marriott-desert-springs-resort-and-spa/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'California',
                },
                city: 'Palm Desert',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [
                  {
                    imageUrls: {
                      classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-golf-0117-hor-clsc.jpg',
                    },
                    alternateDescription: 'Palm Springs Golf Courses',
                    title: 'ctdca-golf-0117',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-golf-waterfalls-14289-31584:Classic-Hor',
                    },
                    alternateDescription: 'Two golfers in the distance on the green',
                    title: 'Golf waterfalls 14289',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-mini-golf-20177:Classic-Hor',
                    },
                    alternateDescription: 'Four people playing mini golf',
                    title: 'Mini Golf',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-golf-club-2658-hor-clsc.jpg',
                    },
                    alternateDescription: 'Desert Springs Golf Club',
                    title: 'ctdca-golf-club-2658',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-teeing-off-31464-47632:Classic-Hor',
                    },
                    alternateDescription: 'Male tees off on 16th hole with mountain views',
                    title: 'teeing off 31464',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-palm-course-18th-23227-31411:Classic-Hor',
                    },
                    alternateDescription: 'Palm Springs golf courses',
                    title: 'Palm Course 18th 23227',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '',
                    },
                    alternateDescription: 'Mini Golf',
                    title: 'ctdca-golf-0092',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-driving-range-24161-73740:Classic-Hor',
                    },
                    alternateDescription: 'golf cart driving away on golf course',
                    title: 'Driving Range 24161',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-putting-33283-57152:Classic-Hor',
                    },
                    alternateDescription: 'Male and female golfer putting with Resort in back',
                    title: 'Putting 33283',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-golf-club-9583-hor-clsc.jpg',
                    },
                    alternateDescription: 'Desert Springs Golf Club',
                    title: 'ctdca-golf-club-9583',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-elephant-lawn-day-time-38438:Classic-Hor',
                    },
                    alternateDescription: 'Elephant Lawn Day Time',
                    title: 'Elephant Lawn Day Time',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-golf-2654-hor-clsc.jpg',
                    },
                    alternateDescription: 'Desert Springs Golf Club',
                    title: 'ctdca-golf-2654',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/ctdca-golf-0111:Classic-Hor',
                    },
                    alternateDescription: 'Golf in Miniature',
                    title: 'ctdca-golf-0111',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-mini-golf-4-26929:Classic-Hor',
                    },
                    alternateDescription: 'Four people playing mini golf',
                    title: 'Mini Golf 4',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '',
                    },
                    alternateDescription: 'Desert Springs Golf Club',
                    title: 'ctdca_phototour117',
                  },
                ],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Family Accommodation in Palm Desert',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-aerial-6588-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Desert Spring Resorts',
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/jw-ctdca-overview-front-drive42128-30650:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Womand walking on bridge over water',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-outdoor-bridge-37203:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Family Resort in Palm Desert, CA',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-aerial-6587-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Flamingos',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-island-0074-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Five people standing around a communal table',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-people-at-lobby-23036:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Five people standing around a communal table',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-people-at-lobby-36022:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Five people meeting in lobby on sofa ',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-lobby-35399:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Resort in Coachella Valley',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-jw-desert-springs-11345:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'things to do in palm desert',
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/jw-ctdca-bird-s-eye-view-17171-69863:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Palm Springs golf courses',
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/jw-ctdca-palm-course-18th-23227-31411:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Palm Desert Family Resort',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-oasis-pools-22209:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-lobby-8301-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'Palm Desert Resort',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CTDCA/ctdca-exterior-0073-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Cactus on the hotel ground',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/jw-ctdca-jw-garden-10803:Classic-Hor',
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'BNAGO',
            basicInformation: {
              name: 'Gaylord Opryland Resort & Convention Center',
              descriptions: [
                {
                  text: 'Situated in the heart of Nashville, Gaylord Opryland Resort & Convention Center welcomes guests to a stunning resort experience. From SoundWaves, the city’s premier aquatic attraction, to an exciting lineup of activities and entertainment, there’s something for the entire family to enjoy. Guests at our family-friendly Nashville resort can explore nine acres of airy indoor garden atriums, award-winning restaurants and bars, the world-class Relâche Spa, and a state-of-the-art fitness center. For those planning or attending their next meeting or event, the resort features more than 750,000 sq. ft. of flexible meeting space and 2,888 guest rooms, including 171 suites and five themed presidential suites. The resort is located near Nashville Intl. Airport, downtown and attractions like the Grand Ole Opry and Ryman Auditorium for guests wishing to explore Music City. From seasonal events and entertainment to high-end amenities and stunning spaces, Gaylord Opryland welcomes you to enjoy more of what you love.',
                },
                {
                  text: 'The Gaylord Opryland Resort & Convention Center offers stylish hotel suites in Nashville. This luxury resort features numerous restaurants, 700,000 sq ft of event space, an on-site spa, a golf course and the SoundWaves water attraction.',
                },
                {
                  text: 'The Gaylord Opryland Resort & Convention Center offers stylish hotel suites in Nashville. This luxury resort features numerous restaurants, 700,000 sq ft of event space, an on-site spa, a golf course and the SoundWaves water attraction.',
                },
                {
                  text: 'Experience Nashville like never before at Gaylord Opryland Resort & Convention Center, offering luxury hotel rooms in Nashville, TN. Enjoy easy access to attractions like the Grand Ole Opry, Opry Mills, Country Music Hall of Fame and downtown Nashville.',
                },
                {
                  text: 'Experience Nashville like never before at Gaylord Opryland Resort & Convention Center, offering luxury hotel rooms in Nashville, TN. Enjoy easy access to attractions like the Grand Ole Opry, Opry Mills, Country Music Hall of Fame and downtown Nashville.',
                },
                {
                  text: 'Daily Sunrise Yoga Meditation for 2 guests |Daily Complex Shuttle to/from the Inn at Opryland and Opry Mills Mall for 2 guests |Daily Range access for 2 guests.  Includes a bucket of balls, clubs and transportation to/from the Gaylord Springs |Daily Enhanced High-speed internet access |Bonvoy Elite Amenity:  Daily Spa Day pass',
                },
                {
                  text: 'Upscale Resort & Water Attraction Near the Best of Nashville',
                },
                {
                  text: 'Let luxury lead the way in our Nashville hotel rooms',
                },
                {
                  text: 'A one-of-a-kind luxury resort in Nashville, TN, with water attractions, restaurants and event venues',
                },
              ],
              brand: {
                name: 'Gaylord Hotels',
                id: 'GE',
              },
              websiteUrl:
                'https://www.marriott.com/en-us/hotels/bnago-gaylord-opryland-resort-and-convention-center/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Tennessee',
                },
                city: 'Nashville',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [
                  {
                    imageUrls: {
                      classicHorizontal: '/content/dam/marriott-renditions/BNAGO/bnago-golf-0047-hor-clsc.jpg',
                    },
                    alternateDescription: 'Gaylord Springs Golf Links Clubhouse',
                    title: 'bnago-golf-0047',
                  },
                ],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Gaylord Opryland Exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/ge-bnago-bnagoex-830620-22458:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'Delta Atrium',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/ge-bnago-bnagost-830068-42725:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'DIning',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/ge-bnago-brunch-dining-17819:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Cascades Atrium Waterfalls',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/BNAGO/bnago-cascades-0104-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Hot Coffee Cup',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'AHNRZ',
            basicInformation: {
              name: 'The Ritz-Carlton Reynolds, Lake Oconee',
              descriptions: [
                {
                  text: 'As close to nature’s perfection as you can get, yet not far from Atlanta, The Ritz-Carlton Reynolds, Lake Oconee is one of the most luxurious lakeside resorts in Georgia, a place where lake house-like charm, world-class accommodations and highly personalized service create an unforgettable experience. A stay at one of the most rustically elegant lake resorts in Georgia opens the door to spectacular golf experiences on five championship courses, a relaxing day of fishing or boating on Lake Oconee or at a world-class spa, or dining lakeside as stars fill the sky. It is where multi-generations of families gather to make vacation memories. Where Atlanta city dwellers trade city noise for nature’s tranquil melodies. Where golfers discover 90 reasons to pack their lucky golf shoes. And where brides realize that wedding dreams really do come true. It is a place that when memories are made, they will stay with you forever.',
                },
                {
                  text: 'As close to nature’s perfection as you can get, yet not far from Atlanta, The Ritz-Carlton Reynolds, Lake Oconee is a place where lodge-like charm, world-class amenities and highly personalized service create unforgettable lakeside experiences.',
                },
                {
                  text: 'As close to nature’s perfection as you can get, yet not far from Atlanta, The Ritz-Carlton Reynolds, Lake Oconee is a place where lodge-like charm, world-class amenities and highly personalized service create unforgettable lakeside experiences.',
                },
                {
                  text: 'A rustically elegant lakeside resort that resides in harmony with nature and luxury',
                },
                {
                  text: 'A rustically elegant lakeside resort that resides in harmony with nature and luxury',
                },
                {
                  text: 'Nightly Overnight Valet Parking for 1 vehicle per Room Reservation |Shuttle service to The Oconee, The Landing, Great Waters, The Preserve and The National Golf Courses |Daily 1 hour Tennis Court Access & unlimited Driving Range access. |Tennis Racquets, Tennis Balls and Golf Balls included, with transportation provided by the Resort. |Daily 2hr Bike Rental for guests up to 2 guests |Daily Spa, Sauna, Steam, Locker Room, and 24 hr Fitness Center access, including daily Fitness Class taught by professional instructors. |Daily access to non-motorized watercraft for 2 guests for 2 hours (Kayaks and Paddleboards) |Daily Dock Fishing for 2 guests for 2 hours (fishing rods and live bait included) |Daily Enhanced Hi-Speed Internet Access',
                },
                {
                  text: 'Lake Oconee, Georgia',
                },
                {
                  text: 'At The Ritz-Carlton Reynolds, Lake Oconee, discover a lakeside resort, only 75 miles from Atlanta.',
                },
              ],
              brand: {
                name: 'The Ritz-Carlton',
                id: 'RZ',
              },
              websiteUrl: 'https://www.ritzcarlton.com/en/hotels/georgia/reynolds',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Georgia',
                },
                city: 'Greensboro',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/50537276-kingdom_drone_fall_2017:Classic-Hor',
                    },
                    alternateDescription: 'Ritz Carlton Hotel image',
                    title: '50537276-kingdom-drone',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/is/image/marriotts7prod/50537272-caddie-services:Classic-Hor',
                    },
                    alternateDescription: 'Ritz Carlton Hotel image',
                    title: '50537272-caddie-services',
                  },
                ],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'The front of the lake house surrounded by trees',
                        imageUrls: {
                          classicHorizontal: '',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lake House Backyard',
                        imageUrls: {
                          classicHorizontal: '',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lake Oconee Sunset',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/AHNRZ/ahnrz-sunset-0030-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Resort-Lobby',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/rz-ahnrz-resort-lobby-21287:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Aerial View - Lake Oconee',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/ahnrz-aerial-view-1617:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'A boat is cruising along the water of Lake Oconee.',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/rz-ahnrz-lake-oconee-29243:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Aerial View - Lake Oconee & Oconee Course',
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/AHNRZ/ahnrz-aerial-view-1615-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Fire Globe at Dawn',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/rz-ahnrz-fire-globe--34129-63903:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Aerial View',
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/AHNRZ/ahnrz-aerial-view-1620-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'The Docks at the Pointe',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/rz-ahnrz-pointe-docks-17296:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Sun setting over the Resort',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/rz-ahnrz-resort-exterior-30391:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'The backyard of the Resort with the Infinity Pool',
                        imageUrls: {
                          classicHorizontal: '',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Arial View of the Resort',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/rz-ahnrz-aerial-view-31777:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby Fireplace',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/AHNRZ/ahnrz-fireplace-1237-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Nighttime Resort Exterior ',
                        imageUrls: {
                          classicHorizontal: '',
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'DENSE',
            basicInformation: {
              name: 'Courtyard Denver Tech Center',
              descriptions: [
                {
                  text: "The right blend of thoughtful amenities and services can be found at Courtyard Denver Tech Center. Travelers will value our hotel's prime location in Greenwood Village, CO, near a multitude of major companies. Explore popular attractions surrounding our hotel in Denver near Fiddler's Green Amphitheater, Parks Meadows shopping mall and Cherry Creek State Park. Our stylish rooms serve as a perfect retreat after a full day of work or play with plush bedding, complimentary high-speed Wi-Fi and ergonomic workspaces. Wake up feeling refreshed and power your morning with healthy breakfast options and Starbucks® coffee at The Bistro. Our restaurant also serves delectable American entrées and signature cocktails in the evening. Experience a new way to play with our Topgolf Swing Suite or stay active with a workout at our fitness center. Whatever brings you to Colorado, let Courtyard Denver Tech Center make your visit memorable.",
                },
                {
                  text: 'Experience a successful stay at Courtyard Denver Tech Center, our hotel near Denver, CO, close to Park Meadows Mall. Enjoy spacious rooms, complimentary high-speed Wi-Fi, a restaurant, a fitness center, an indoor pool, two meeting rooms and free parking.',
                },
                {
                  text: 'Experience a successful stay at Courtyard Denver Tech Center, our hotel near Denver, CO, close to Park Meadows Mall. Enjoy spacious rooms, complimentary high-speed Wi-Fi, a restaurant, a fitness center, an indoor pool, two meeting rooms and free parking.',
                },
                {
                  text: "Situated right off Interstate 25, our hotel in Greenwood Village, CO, provides convenient access to popular Denver destinations including Fiddler's Green Amphitheatre, Swedish Medical Center, Craig Hospital, Inverness Business Park and Park Meadows Mall.",
                },
                {
                  text: "Situated right off Interstate 25, our hotel in Greenwood Village, CO, provides convenient access to popular Denver destinations including Fiddler's Green Amphitheatre, Swedish Medical Center, Craig Hospital, Inverness Business Park and Park Meadows Mall.",
                },
                {
                  text: 'Elevate Your Stay at our Hotel in Denver Tech Center',
                },
                {
                  text: 'Unwind and feel at home in our Denver Tech Center suites',
                },
                {
                  text: 'Modern hotel in Denver Tech Center with on-site dining options and business amenities',
                },
              ],
              brand: {
                name: 'Courtyard',
                id: 'CY',
              },
              websiteUrl: 'https://www.marriott.com/en-us/hotels/dense-courtyard-denver-tech-center/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Colorado',
                },
                city: 'Greenwood Village',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Courtyard',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/cy-dense-courtyard-27965:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Welcome Pedestals',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Outdoor Terrace',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/cy-dense-outdoor-terrace-26598:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/cy-dense-exterior-28405:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby Lounge',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/cy-dense-lobby-lounge-28619:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby Lounge Area',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/cy-dense-lobby-lounge-area-32955:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Exterior',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/cy-dense-exterior-14260:Classic-Hor',
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'CSGAB',
            basicInformation: {
              name: 'Auburn Marriott Opelika Resort & Spa at Grand National',
              descriptions: [
                {
                  text: "Auburn Marriott Opelika Resort & Spa is a getaway for the whole family. Located just 90 minutes from Atlanta's Hartsfield International Airport, we provide the ultimate experience in southern hospitality. Enjoy our multiple dining options and outdoor entertainment spaces after your visit to Auburn University or downtown Opelika. Stay connected with free WiFi. Curb your cravings at Southern Oak Restaurant, featuring many enticing American cuisine selections. Put your golf game to the test at RTJ Golf Trail at Grand National, boasting 54 holes across three beautiful courses to challenge yourself as the professionals do. Prepare to be pampered at The Spa at Grand National, the most relaxing way to wind down after a long day on the golf course. Take a dip in one of our three pools, including one indoor pool for a nice swim any time of year. Allow us to host your special event in one of our 13 event rooms, complete with a grand ballroom fit for 500. Escape the ordinary at Auburn Marriott Opelika Resort & Spa",
                },
                {
                  text: 'Auburn Marriott Opelika Resort & Spa provides upscale rooms, complimentary Wi-Fi, spa, dining, tennis, pickleball, daily programming and 54 holes of golf. Host your next meeting or wedding at the hotel with 15,220 square feet of event space.',
                },
                {
                  text: 'Auburn Marriott Opelika Resort & Spa provides upscale rooms, complimentary Wi-Fi, spa, dining, tennis, pickleball, daily programming and 54 holes of golf. Host your next meeting or wedding at the hotel with 15,220 square feet of event space.',
                },
                {
                  text: 'Auburn Marriott Opelika Resort & Spa at Grand National is located near downtown Opelika, Auburn University, Jordan Hare Stadium, Robert Jones Golf Trail at Grand National and Julie Collins Smith Museum of Fine Art.',
                },
                {
                  text: 'Auburn Marriott Opelika Resort & Spa at Grand National is located near downtown Opelika, Auburn University, Jordan Hare Stadium, Robert Jones Golf Trail at Grand National and Julie Collins Smith Museum of Fine Art.',
                },
                {
                  text: 'Daily Unlimited golf range balls with shuttle to Grand National golf practice facility for 2 guests |Nightly S’mores at resort firepit for two guests |Daily Tennis and Pickleball w/ rackets and balls for 2 guests |Daily 2hr Cruiser bike rentals for 2 guests. Includes helmets |Daily 2hr Game room access for 2 guests.  Includes billiards, air hockey, or foosball. |Daily Spa Fitness Center day pass for 2 guests |Daily Enhanced High-speed internet access',
                },
                {
                  text: 'Anticipate an amazing stay at our Opelika, Alabama hotel',
                },
                {
                  text: 'Live luxuriously in our updated Opelika hotel rooms',
                },
                {
                  text: 'Upscale resort with pools, golf, spa, dining, tennis, and event venues near Auburn University.',
                },
              ],
              brand: {
                name: 'Marriott Hotels & Resorts',
                id: 'MC',
              },
              websiteUrl:
                'https://www.marriott.com/en-us/hotels/csgab-auburn-marriott-opelika-resort-and-spa-at-grand-national/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Alabama',
                },
                city: 'Opelika',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CSGAB/csgab-lobby-0075-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Auburn, Alabama hotel entrance',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CSGAB/csgab-entrance-0028-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'Entrance',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CSGAB/csgab-entrance-0074-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'Outdoor Exterior View',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-csgab-property-exterior-11011:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CSGAB/csgab-lobby-0076-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Hotel lobby in Auburn, Alabama',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/csgab-lobby-7705:Classic-Hor',
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'LEXKY',
            basicInformation: {
              name: 'Marriott Lexington Griffin Gate Golf Resort & Spa',
              descriptions: [
                {
                  text: 'Discover a lavish escape at the Marriott Lexington Griffin Gate Golf Resort & Spa. Nestled in the Bluegrass Country hills in Lexington, KY, our resort is known for its scenic surroundings and ideal location in the “Horse Capital of the World." Our recently updated hotel features on-site Kentucky-inspired dining at Bluegrass Bistro, state-of-the-art fitness amenities, upscale accommodations, a full-service wellness spa, a Rees Jones-designed 18-hole championship golf course, a bourbon and wine-tasting loft, and an expansive 28,901 square feet of space. Our extensive indoor and outdoor venues set the scene for memorable business meetings and social gatherings. After days of exploring, retire to your spacious guest room or suite, featuring plush Tempur-Pedic beds, marble bathrooms, and upscale amenities. Whether you\'re here for business or pleasure, our resort promises a memorable experience filled with comfort and unforgettable moments.',
                },
                {
                  text: 'Discover a lavish escape at the Marriott Lexington Griffin Gate Golf Resort & Spa. Unwind at our recently updated hotel offering Kentucky-inspired dining, cutting-edge fitness facilities, upscale guest rooms, a wellness spa, premier golf course, and more.',
                },
                {
                  text: 'Discover a lavish escape at the Marriott Lexington Griffin Gate Golf Resort & Spa. Unwind at our recently updated hotel offering Kentucky-inspired dining, cutting-edge fitness facilities, upscale guest rooms, a wellness spa, premier golf course, and more.',
                },
                {
                  text: 'Located near downtown Lexington, our newly updated hotel near Kentucky Horse Park offers easy access to prime attractions like Keeneland, downtown Lexington, and the Kentucky Bourbon Trail. Blue Grass Airport - Lexington (LEX) is nine miles Northeast.',
                },
                {
                  text: 'Located near downtown Lexington, our newly updated hotel near Kentucky Horse Park offers easy access to prime attractions like Keeneland, downtown Lexington, and the Kentucky Bourbon Trail. Blue Grass Airport - Lexington (LEX) is nine miles Northeast.',
                },
                {
                  text: 'Daily 2hr Bike Rentals for 2 guests |Daily 1hr Pickleball equipment and court rental for 2 guests |Daily “Kentucky Cocktail” featuring bourbon and Ale-8-One for 2 guests |Daily 1hr Putting Green access with rental equipment for 2 guests |Daily S’mores kits to use at our extra-large fire pits |Daily Enhanced Hi-Speed Internet Access',
                },
                {
                  text: 'Experience Southern Charm at our Lexington, KY Resort',
                },
                {
                  text: 'Upscale Rooms & Suites in Lexington, KY',
                },
                {
                  text: 'Ideal location near downtown featuring 5 restaurants, full-service spa, 18-hole golf course & more.',
                },
              ],
              brand: {
                name: 'Marriott Hotels & Resorts',
                id: 'MC',
              },
              websiteUrl:
                'https://www.marriott.com/en-us/hotels/lexky-lexington-griffin-gate-marriott-golf-resort-and-spa/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Kentucky',
                },
                city: 'Lexington',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Caminetto nella hall',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby mit offenem Kamin',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Chimenea del lobby',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: '로비 벽난로',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Griffin Gate Marriott Resort & Spa',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LEXKY/lexky-exterior-0077-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Камин в лобби',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Entrance',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LEXKY/lexky-entrance-0067-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby Front Desk',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Hotels Lexington KY',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LEXKY/lexky-exterior-0122-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'Exterior',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'مدفأة البهو',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lareira do lobby',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: '大堂壁炉',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Cheminée du hall',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby Seating',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/LEXKY/lexky-lobby-0092-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'ロビーの暖炉',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'CHILN',
            basicInformation: {
              name: 'Lincolnshire Marriott Resort',
              descriptions: [
                {
                  text: "Lincolnshire Marriott Resort welcomes you to the tranquil enclave of Lincolnshire, IL. Our hotel near Buffalo Grove, Vernon Hills, Deerfield, Wheeling and Chicago's North Shore sits within the Chicago northern suburbs. Inspire your senses at our corporate resort, which invites hotel guests who work best in well-designed environments. Step into a welcoming lobby, visit four restaurants and bars. More amenities include an on-site M Club, full-service Starbucks and resort spa. Plan the event of the year in 64,000 square feet of meeting and event space. Our ballroom venues include the latest AV tech, plus we offer three outdoor pavilions with spectacular views of Illinois' North Shore. Stroll through our beautiful, 175-acre resort, tee off at our championship golf course or attend the Marriott Theatre, one of Chicago’s largest theaters in the round. Lincolnshire Marriott Resort dazzles hotel guests with a range of services and amenities. More than a hotel, we're a true suburban resort.",
                },
                {
                  text: 'Lincolnshire Marriott Resort treats guests to 175 wooded acres near Buffalo Grove, IL. Our Lincolnshire hotel features 390 hotel rooms, an M Club, golf course, outdoor pavilions, three restaurants, two bars and a live musical Marriott Theatre.',
                },
                {
                  text: 'Lincolnshire Marriott Resort treats guests to 175 wooded acres near Buffalo Grove, IL. Our Lincolnshire hotel features 390 hotel rooms, an M Club, golf course, outdoor pavilions, three restaurants, two bars and a live musical Marriott Theatre.',
                },
                {
                  text: "Located on 175 wooded acres. This iconic resort places guests near Six Flags, Great Lakes, Ravinia Festival, and the attractions of Lake County, IL. 26 minutes from O'Hare Airport (ORD), 40 minutes from downtown Chicago, & 1 hour from Milwaukee, Wisconsin",
                },
                {
                  text: "Located on 175 wooded acres. This iconic resort places guests near Six Flags, Great Lakes, Ravinia Festival, and the attractions of Lake County, IL. 26 minutes from O'Hare Airport (ORD), 40 minutes from downtown Chicago, & 1 hour from Milwaukee, Wisconsin",
                },
                {
                  text: "Explore Chicago's North Shore & attractions of Lake County.",
                },
                {
                  text: 'Enjoy serene views from your suite in Lincolnshire, IL',
                },
                {
                  text: 'Resort hotel with four restaurants & bars, M Club, spa, live theatre & golf course. Pet Friendly',
                },
              ],
              brand: {
                name: 'Marriott Hotels & Resorts',
                id: 'MC',
              },
              websiteUrl: 'https://www.marriott.com/en-us/hotels/chiln-lincolnshire-marriott-resort/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Illinois',
                },
                city: 'Lincolnshire',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [
                  {
                    imageUrls: {
                      classicHorizontal: '',
                    },
                    alternateDescription: "crane's landing golf course",
                    title: 'chiln-golf-2994',
                  },
                  {
                    imageUrls: {
                      classicHorizontal: '/content/dam/marriott-renditions/CHILN/chiln-golf-3606-hor-clsc.jpg',
                    },
                    alternateDescription: 'Golf Fairway Hole 18',
                    title: 'chiln-golf-3606',
                  },
                ],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Porte Cochere',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CHILN/chiln-porte-0072-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: true,
                        alternateDescription: 'Marriott Greatroom of hotel resort in Illinois',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/CHILN/chiln-greatroom-0077-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Front Entrance of resort in Illinois',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-chiln-mh-chiln-026-26486:Classic-Hor',
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        {
          node: {
            id: 'PBIIR',
            basicInformation: {
              name: 'Marriott Hutchinson Island Beach Resort, Golf & Marina',
              descriptions: [
                {
                  text: 'Discover the newly reimagined Marriott Hutchinson Island Beach Resort, Golf & Marina, where modern and sophisticated ambiance seamlessly complements the natural allure of this captivating destination. Nestled along 200 acres of pristine shoreline, our refreshed resort is poised to redefine the art of coastal living. We offer an authentic Florida beach getaway surrounded by lush grounds, hosting curated events and various resort activities, including our celebrated 18-hole executive golf course, six pickleball courts, direct beach access, and a private marina. Envision yourself in our newly redesigned guestrooms with private balconies and astonishing views. Immerse yourself in our dining venues, whether savoring seafood or indulging in a light poolside lunch; our innovative menus are crafted to elevate your culinary journey. Boasting 30,000 sq. ft. of versatile indoor and outdoor spaces, we bring a laid-back yet refined beachside ambiance to your gatherings.',
                },
                {
                  text: 'Enjoy 200 idyllic acres along Florida’s Treasure Coast at the newly elevated Marriott Hutchinson Island Beach Resort, Golf & Marina. Savor warm weather splendor and sun-kissed days with a coastal backdrop; the perfect destination for your next getaway.',
                },
                {
                  text: 'Enjoy 200 idyllic acres along Florida’s Treasure Coast at the newly elevated Marriott Hutchinson Island Beach Resort, Golf & Marina. Savor warm weather splendor and sun-kissed days with a coastal backdrop; the perfect destination for your next getaway.',
                },
                {
                  text: 'Explore with ease from Hutchinson Island Marriott Beach Resort & Marina. Our oceanfront hotel in Hutchinson Island, Florida, is accessible from PBI, FLL and MCO airports. Visit the Manatee Observation & Education Center and Hallstrom Planetarium.',
                },
                {
                  text: 'Explore with ease from Hutchinson Island Marriott Beach Resort & Marina. Our oceanfront hotel in Hutchinson Island, Florida, is accessible from PBI, FLL and MCO airports. Visit the Manatee Observation & Education Center and Hallstrom Planetarium.',
                },
                {
                  text: 'Bike rentals for 2ppl, Boogie Board Rentals for 2ppl, self Parking, Tennis Court & Raquets use, Range Balls and Golf Clubs use.  Enhanced Wi-Fi.',
                },
                {
                  text: 'Experience our newly reimagined beachside resort',
                },
                {
                  text: 'Relax in comfort in our Hutchinson Island hotel rooms',
                },
                {
                  text: 'Stuart, FL, resort with golf, tennis, pools, marina, water sports and event venues.',
                },
              ],
              brand: {
                name: 'Marriott Hotels & Resorts',
                id: 'MC',
              },
              websiteUrl:
                'https://www.marriott.com/en-us/hotels/pbiir-marriott-hutchinson-island-beach-resort-golf-and-marina/overview/',
            },
            contactInformation: {
              address: {
                stateProvince: {
                  label: null,
                  description: 'Florida',
                },
                city: 'Stuart',
              },
            },
            digitalAssets: {
              golfImagesByProductType: {
                assets: [],
              },
            },
            media: {
              photoGallery: {
                hotelView: {
                  edges: [
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Aerial View',
                        imageUrls: {
                          classicHorizontal:
                            '/content/dam/marriott-renditions/PBIIR/pbiir-aerial-view-4804-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Стойка ресепшен',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Main Hotel Front Entrance',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PBIIR/pbiir-entrance-0063-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'フロントデスク',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Exterior',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PBIIR/pbiir-exterior-3976-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Reception',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Hotel Lobby Common Area',
                        imageUrls: {
                          classicHorizontal:
                            '/is/image/marriotts7prod/mc-pbiir-hotel-lobby-common-area-40323:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: '프런트 데스크',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Recepção',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Recepción',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Réception',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Sandpiper Tower - Exterior',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PBIIR/pbiir-exterior-9132-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'مكتب الاستعلامات',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Rezeption',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Hotels in Stuart Florida',
                        imageUrls: {
                          classicHorizontal: '/content/dam/marriott-renditions/PBIIR/pbiir-exterior-0036-hor-clsc.jpg',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: 'Lobby Common Area',
                        imageUrls: {
                          classicHorizontal: '/is/image/marriotts7prod/mc-pbiir-lobby-29936:Classic-Hor',
                        },
                      },
                    },
                    {
                      node: {
                        isPrimaryArchitectural: false,
                        isPrimaryServices: false,
                        isPrimary: false,
                        alternateDescription: '前台',
                        imageUrls: {
                          classicHorizontal: null,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      ],
      facets: [
        {
          buckets: [
            {
              code: 'africa',
              label: 'Africa',
              description: 'Africa',
              count: 5,
            },
            {
              code: 'asia',
              label: 'Asia',
              description: 'Asia',
              count: 15,
            },
            {
              code: 'australia-pi',
              label: 'Australia & Pacific Islands',
              description: 'Australia & Pacific Islands',
              count: 4,
            },
            {
              code: 'canada',
              label: 'Canada',
              description: 'Canada',
              count: 5,
            },
            {
              code: 'caribbean',
              label: 'Caribbean',
              description: 'Caribbean',
              count: 10,
            },
            {
              code: 'central-america',
              label: 'Central America',
              description: 'Central America',
              count: 9,
            },
            {
              code: 'europe',
              label: 'Europe',
              description: 'Europe',
              count: 23,
            },
            {
              code: 'mexico',
              label: 'Mexico',
              description: 'Mexico',
              count: 4,
            },
            {
              code: 'middle-east',
              label: 'Middle East',
              description: 'Middle East',
              count: 1,
            },
            {
              code: 'south-america',
              label: 'South America',
              description: 'South America',
              count: 3,
            },
            {
              code: 'united-states',
              label: 'United States',
              description: 'United States',
              count: 83,
            },
          ],
          type: {
            code: 'leisure-region',
            label: 'Leisure Region',
            description: 'Leisure Region',
            count: 11,
          },
        },
        {
          buckets: [
            {
              code: 'US',
              label: 'USA',
              description: 'USA',
              count: 83,
            },
          ],
          type: {
            code: 'countries',
            label: 'Countries / Regions',
            description: 'Countries / Regions',
            count: 1,
          },
        },
        {
          buckets: [
            {
              code: 'beach',
              label: 'Beach',
              description: 'Beach',
              count: 12,
            },
            {
              code: 'casino',
              label: 'Casino',
              description: 'Casino',
              count: 1,
            },
            {
              code: 'family',
              label: 'Family travel',
              description: 'Family travel',
              count: 51,
            },
            {
              code: 'spa',
              label: 'Full service spa',
              description: 'Full service spa',
              count: 57,
            },
            {
              code: 'golf',
              label: 'Golf',
              description: 'Golf',
              count: 83,
            },
            {
              code: 'ski',
              label: 'Ski/snowboard',
              description: 'Ski snowboard',
              count: 1,
            },
            {
              code: 'tennis',
              label: 'Tennis',
              description: 'Tennis',
              count: 40,
            },
          ],
          type: {
            code: 'activities-on-site',
            label: 'Activities',
            description: 'Activities On-site',
            count: 7,
          },
        },
        {
          buckets: [
            {
              code: 'AL',
              label: 'Alabama',
              description: 'Alabama',
              count: 4,
            },
            {
              code: 'AZ',
              label: 'Arizona',
              description: 'Arizona',
              count: 12,
            },
            {
              code: 'CA',
              label: 'California',
              description: 'California',
              count: 15,
            },
            {
              code: 'CO',
              label: 'Colorado',
              description: 'Colorado',
              count: 1,
            },
            {
              code: 'FL',
              label: 'Florida',
              description: 'Florida',
              count: 19,
            },
            {
              code: 'GA',
              label: 'Georgia',
              description: 'Georgia',
              count: 3,
            },
            {
              code: 'HI',
              label: 'Hawaii',
              description: 'Hawaii',
              count: 5,
            },
            {
              code: 'IL',
              label: 'Illinois',
              description: 'Illinois',
              count: 1,
            },
            {
              code: 'KY',
              label: 'Kentucky',
              description: 'Kentucky',
              count: 1,
            },
            {
              code: 'MD',
              label: 'Maryland',
              description: 'Maryland',
              count: 2,
            },
            {
              code: 'MA',
              label: 'Massachusetts',
              description: 'Massachusetts',
              count: 1,
            },
            {
              code: 'MI',
              label: 'Michigan',
              description: 'Michigan',
              count: 1,
            },
            {
              code: 'MS',
              label: 'Mississippi',
              description: 'Mississippi',
              count: 2,
            },
            {
              code: 'NV',
              label: 'Nevada',
              description: 'Nevada',
              count: 2,
            },
            {
              code: 'NY',
              label: 'New York',
              description: 'New York',
              count: 1,
            },
            {
              code: 'OH',
              label: 'Ohio',
              description: 'Ohio',
              count: 2,
            },
            {
              code: 'PA',
              label: 'Pennsylvania',
              description: 'Pennsylvania',
              count: 1,
            },
            {
              code: 'TN',
              label: 'Tennessee',
              description: 'Tennessee',
              count: 2,
            },
            {
              code: 'TX',
              label: 'Texas',
              description: 'Texas',
              count: 4,
            },
            {
              code: 'UT',
              label: 'Utah',
              description: 'Utah',
              count: 2,
            },
            {
              code: 'VA',
              label: 'Virginia',
              description: 'Virginia',
              count: 2,
            },
          ],
          type: {
            code: 'states',
            label: 'States / Provinces',
            description: 'States / Provinces',
            count: 21,
          },
        },
      ],
    },
  },
};

export const getCourseLocationsMock = {
  request: {
    query: golfCoursePropertiesDetails,
    variables: {
      search: {
        facets: {
          terms: [
            {
              type: 'COUNTRIES',
              dimensions: [],
            },
            {
              type: 'ACTIVITIES',
              dimensions: ['golf'],
            },
            {
              type: 'LEISURE_REGIONS',
              dimensions: ['central-america'],
            },
            {
              type: 'STATES',
              dimensions: [],
            },
          ],
        },
      },
      limit: 18,
      offset: 0,
    },
  },
  result: golfCourseLocationsMock,
};

export const errorMock = {
  request: {
    query: golfCoursePropertiesDetails,
    variables: {
      search: {
        facets: {
          terms: [
            {
              type: 'COUNTRIES',
              dimensions: [],
            },
            {
              type: 'ACTIVITIES',
              dimensions: ['golf'],
            },
            {
              type: 'LEISURE_REGIONS',
              dimensions: ['central-america'],
            },
            {
              type: 'STATES',
              dimensions: [],
            },
          ],
        },
      },
      limit: 18,
      offset: 0,
    },
  },
  error: new Error('Network Error'),
};
