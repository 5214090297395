import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules/utils/constants/constants';
import rtl from 'styled-components-rtl';

export const Styledcontainer = styled.div`
  border-top: ${toRem(1)} solid ${baseVariables.color.neutral40};
  border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral40};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: ${toRem(16)} 0;
  row-gap: ${toRem(16)};

  @media ${baseVariables.mediaQuery.largeDesktop},
    @media ${baseVariables.mediaQuery.desktop},
    @media ${baseVariables.mediaQuery.tablet} {
    margin: auto;
    column-gap: ${toRem(24)};
    padding: ${toRem(32)} ${toRem(135)};
    flex-direction: row;
  }

  .eyebrow-class {
    width: 100%;
    text-align: -webkit-center;
    @media ${baseVariables.mediaQuery.largeDesktop},
      @media ${baseVariables.mediaQuery.desktop},
      @media ${baseVariables.mediaQuery.tablet} {
      text-align: left;

      ${rtl`
        &:is(${constants.RTL_LANG_LIST}){
          text-align: right;
        }
      `}
    }
  }

  .promotional-image {
    height: ${toRem(198)};
    width: ${toRem(241)};
    border-radius: ${toRem(14)};
    display: flex;
    @media ${baseVariables.mediaQuery.mobile && baseVariables.mediaQuery.allTablets} {
      width: 100%;
      object-fit: cover;
    }
  }

  .explore {
    height: auto;
    margin-bottom: ${toRem(24)};
    @media ${baseVariables.mediaQuery.mobile && baseVariables.mediaQuery.allTablets} {
      margin-bottom: ${toRem(16)};
    }
  }

  .image-container {
    width: ${toRem(241)};
    text-align: -webkit-center;
    -webkit-box-pack: center;

    @media ${baseVariables.mediaQuery.largeDesktop},
      @media ${baseVariables.mediaQuery.desktop},
      @media ${baseVariables.mediaQuery.tablet} {
      text-align: left;

      ${rtl`
        &:is(${constants.RTL_LANG_LIST}){
          text-align: right;
        }
      `}
    }
  }
  .detail-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    @media ${baseVariables.mediaQuery.largeDesktop},
      @media ${baseVariables.mediaQuery.desktop},
      @media ${baseVariables.mediaQuery.tablet} {
    }

    .destination {
      text-align: center;

      @media ${baseVariables.mediaQuery.largeDesktop} {
        text-align: left;

        ${rtl`
          &:is(${constants.RTL_LANG_LIST}){
            text-align: right;
          }
        `}
      }
    }
  }

  .button1 {
    width: 100%;
    text-align: -webkit-center;
    @media ${baseVariables.mediaQuery.largeDesktop},
      @media ${baseVariables.mediaQuery.desktop},
      @media ${baseVariables.mediaQuery.tablet} {
      text-align: left;

      ${rtl`
        &:is(${constants.RTL_LANG_LIST}){
          text-align: right;
        }
      `}
    }
  }
`;
