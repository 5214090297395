import React, { FC } from 'react';
import { StyledSearchButtonPill } from './SearchButtonPill.styles';
import { Icon, Button } from '@marriott/mi-ui-library';
import { SearchButtonPillProps } from './SearchButtonPill.types';
import clsx from 'clsx';

export const SearchButtonPill: FC<SearchButtonPillProps> = ({
  text,
  filterType,
  onSelectedPillClick,
  customTrackValue,
}) => {
  const deselectFilter = () => {
    onSelectedPillClick(text, filterType);
  };

  return (
    <StyledSearchButtonPill>
      {!!text && (
        <div className="search-button-pill custom_click_track">
          <span>
            <Button
              isLink={false}
              className={clsx('t-font-xs ml-2')}
              aria-label={text}
              custom_click_track_value={customTrackValue || ''}
            >
              <span>{text}</span>{' '}
              <span onClick={deselectFilter}>
                <Icon iconClass="icon-clear clear-button" ariaLabel="icon-clear" />
              </span>
            </Button>
          </span>
        </div>
      )}
    </StyledSearchButtonPill>
  );
};
