/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect, useRef, useCallback, useContext, useMemo, createContext } from 'react';
import { Types, Button, Heading } from '@marriott/mi-ui-library';
import { FeaturedOutletsProps } from './FeaturedOutlets.types';
import { StyledFeaturedOutlets } from './FeaturedOutlets.styles';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { rnbErrorState, rnbErrorStore } from '../../modules/store/rnbErrorStore';
import Cookies from 'js-cookie';
import searchResultsMockData from './__mocks__/propertiesByIds_UXL_RESPONSE.json';
import Glide from '@glidejs/glide';
import { outletsByRestaurantIds } from '@marriott/mi-rnb-graphql';
import { isServer } from '@marriott/mi-apollo-client-utils';
import {
  getHttpStatusCode,
  getErrorMessage,
  getTitleIconOutlet,
  getOutletShortAddress,
  trackImpression,
  useGetBreakpoint,
} from '../../modules/utils/helpers';
/* eslint-disable @nx/enforce-module-boundaries */
import { PropertyCard } from '@marriott/mi-shop-components';
import { useLazyQuery } from '@apollo/client';
import { TRACKING_CONSTANT, outletNameLimit, PAGES } from '../../modules/utils/constants';
import { StyledPropertyCardContainer } from '../SearchResults/SearchOutletCard/SearchOutletCard.styles';
import { getDirection } from '../../modules/utils/helpers';
import { PageParamsContext } from '../../modules/context';
import clsx from 'clsx';

const { NEXT_PUBLIC_AEM_SITE } = process.env;
const { CITYPAGE } = PAGES;

export const FeaturedOutlets: FC<FeaturedOutletsProps> = (props: FeaturedOutletsProps) => {
  const [restaurantList, setRestaurantList] = useState([]);
  const glideRefOutlet = useRef<HTMLDivElement | any>(null);
  const setErrorState = rnbErrorStore((state: rnbErrorState) => state.setErrorState);
  const [, setFallbackDescriptionText] = useState<string>('');
  const [carousalCount, setCarousalCount] = useState(0);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const { currentPage, currentLocale } = useContext(PageParamsContext);

  const direction = getDirection();
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();
  let iconTitle;
  let filteredArray = null;
  let finalArr: any = [];
  const componentId = 'glide-featured-outlet-card';
  const IMAGE_URL_DOMAIN = 'https://cache.marriott.com';
  const parentGlide = useRef<any>();
  const innerGlideList = useRef<any>([]);
  const {
    CITY_FEATURE_SECTION,
    CITY_FEATURE_RESERVE_BUTTON,
    CITY_FEATURE_RESTAURANT_DETAILS,
    CITY_FEATURED_VIEWALL,
    EARN_REDEEM_CONDENSED_FEATURE,
    EARN_REDEEM_CONDENSED_RESERVE_CTA,
    EARN_REDEEM_CONDENSED_VIEWDETAILS_CTA,
    INTERNAL_LINK,
    EXTERNAL_LINK,
  } = TRACKING_CONSTANT;

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Adding all the restaurants in one single array from Mock
  const searchResultsData: any = searchResultsMockData?.data?.propertiesByIds
    ?.map(res => res?.restaurants?.edges)
    .reduce((acc: any, arr: any) => acc?.concat(arr), []);

  const marshaCodes = props?.marshaCodeList?.map((ids: { marshaCode: string }) => {
    return ids?.marshaCode;
  });
  const outletIds = props?.outletIdsList?.map((ids: { outletId: string }) => {
    return ids?.outletId;
  });

  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');
  let customCssClass = '';

  useEffect(() => {
    if (restaurantList?.length > 0) {
      if (!parentGlide.current?.type) {
        parentGlide.current = new Glide(glideRefOutlet.current, {
          type: 'slider',
          gap: 0,
          startAt: 0,
          focusAt: 'center',
          autoplay: false,
          bound: false,
          rewind: false,
          dragThreshold: false,
          direction: direction,
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
          keyboard: false,
        });

        const glideOnRun = () => {
          const index = Number(
            document
              .getElementsByClassName('glide__slide outer-glide glide__slide--active')[0]
              ?.getAttribute('data-glide-index')
          );

          if (Number(index) === 0 && direction === 'rtl') {
            setIsFirstSlide(true);
            setIsLastSlide(false);
          } else if (Number(index) === restaurantList.length - 1 && direction === 'rtl') {
            setIsFirstSlide(false);
            setIsLastSlide(true);
          } else {
            setIsFirstSlide(false);
            setIsLastSlide(false);
          }

          if (index !== null && restaurantList) {
            const nextIndex = (index + 1) % restaurantList?.length;
            const prevIndex = (index - 1 + restaurantList?.length) % restaurantList?.length;
            const nextImg = glideRefOutlet?.current?.querySelector(`[data-glide-index='${nextIndex}'] img`);
            const prevImg = glideRefOutlet.current?.querySelector(`[data-glide-index='${prevIndex}'] img`);

            if (nextImg) nextImg.setAttribute('loading', 'eager');
            if (prevImg) prevImg.setAttribute('loading', 'eager');
          }
        };

        parentGlide.current.on('run.after', glideOnRun);
      }
    }
  }, [restaurantList]);

  useEffect(() => {
    let innerGlideFix;
    if (restaurantList?.length > 0) {
      if (parentGlide.current) {
        innerGlideFix = innerGlideList?.current?.filter((instance: any) => instance);
        if (innerGlideFix?.length === carousalCount) {
          innerGlideList.current?.forEach((childGlide: any) => {
            childGlide.on('move', function () {
              parentGlide.current.disable();
            });
            childGlide.on('move.after', function () {
              parentGlide.current.enable();
            });
            childGlide.mount();
          });
          // HACK FOR CHILD PIP
          parentGlide.current.on('run.after', () => {
            innerGlideList.current?.forEach((childGlide: any) => {
              document
                .querySelector(`${childGlide.selector} [data-glide-el="controls[nav]"] button.glide__bullet--active`)
                ?.classList.remove('glide__bullet--active');
              document
                .querySelector(`${childGlide.selector} [data-glide-el="controls[nav]"] button`)
                ?.classList.add('glide__bullet--active');
            });
          });
          parentGlide.current?.mount();
        } else if (innerGlideList.current.length === 0) {
          parentGlide.current?.mount();
        }
      }
    }
  }, [restaurantList]);

  const getRestaurantDetailsUrl = (res: any) => {
    if (
      res?.node?.id?.match(/[a-zA-Z]+/)[0] != null &&
      res?.node?.basicInformation?.name != null &&
      res?.node?.hotelName != null &&
      res?.node?.id?.match(/\d+/)[0] != null
    ) {
      //Replace all special char with '-' and remove duplicate '-' and trailing '-' from beginning and end.
      const restaurantDetailsUrl =
        `${props?.variation === 'culinaryView' ? 'dining/restaurant-bar/' : 'restaurant-bar/'}` +
        res?.node?.id
          ?.match(/[a-zA-Z]+/)[0]
          ?.replace(/[^A-Z0-9]+/gi, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase() +
        '-' +
        res?.node?.hotelName
          .replace(/[^A-Z0-9]+/gi, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase() +
        '/' +
        res?.node?.id?.match(/\d+/)[0] +
        '-' +
        res?.node?.basicInformation?.name
          .replace(/[^A-Z0-9]+/gi, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase() +
        '.mi';
      return restaurantDetailsUrl;
    }
    return '';
  };

  const query = outletsByRestaurantIds;
  const [loadOutletData, { error, data }] = useLazyQuery(query);

  useEffect(() => {
    const outletVariable = {
      ids: marshaCodes,
      filter: {
        restaurantIds: outletIds,
      },
      version: 'V2',
    };

    loadOutletData({
      fetchPolicy: isServer ? 'network-only' : 'cache-first',
      errorPolicy: 'all',
      variables: outletVariable,

      context: {
        headers: {
          // Any header, including x-request-id, can be passed in args with query.
          // If you don't pass it in the authLink will generate a random ID.
          'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
          'x-request-id': requestId,
          'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
          'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
        },
      },
    });
  }, [query]);

  const getOutletTitle = (description: string, restaurant: any) => {
    const fallbackDescriptionText = getFallbackDescription(
      props?.firstshortdescription,
      props?.secondshortdescription,
      restaurant
    );

    if (description) {
      return description.length > 242 ? description.slice(0, 242) + '[...]' : description;
    } else if (!description && fallbackDescriptionText) {
      return fallbackDescriptionText?.length > 242
        ? fallbackDescriptionText.slice(0, 242) + '[...]'
        : fallbackDescriptionText;
    }
    return '';
  };

  const getFallbackDescription = useCallback(
    (firstDescription: string, secondDescription: string, restaurant: any): string => {
      let fallbackDesc = '';
      if (restaurant?.node?.diningExperience?.cuisines?.length === 1) {
        fallbackDesc = firstDescription;
      } else {
        fallbackDesc = secondDescription || props?.description;
      }

      if (!props?.isAuthorMode) {
        fallbackDesc = fallbackDesc?.replace(/\[.*?\]/, restaurant?.node?.basicInformation?.name);
        const hotelName = restaurant?.node?.hotelName
          ?.toLowerCase()
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        fallbackDesc = fallbackDesc?.replace(/\[.*?\]/, hotelName + ' ');
        fallbackDesc =
          restaurant?.node?.diningExperience?.cuisines?.length === 1
            ? fallbackDesc?.replace(/\[.*?\]/, restaurant?.node?.diningExperience?.cuisines[0])
            : fallbackDesc;
      }

      setFallbackDescriptionText(fallbackDesc);
      return fallbackDesc;
    },
    [props?.isAuthorMode]
  );

  const getTertiaryLinks = (restaurant: any) => {
    const clicktrack_Pagespecific_reserve =
      currentPage === CITYPAGE
        ? `${CITY_FEATURE_SECTION}|${CITY_FEATURE_RESERVE_BUTTON}|${EXTERNAL_LINK}`
        : `${EARN_REDEEM_CONDENSED_FEATURE}|${EARN_REDEEM_CONDENSED_RESERVE_CTA}|${EXTERNAL_LINK}`;
    if (restaurant?.node?.basicInformation?.reservationURL) {
      const footerlinks = {
        href: restaurant?.node?.basicInformation?.reservationURL,
        target: '_blank',
        rel: 'noreferrer',
        className: 'm-link-tertiary-button outlet-resevation-link custom_click_track ',
        buttonCopy: props?.reserveCtaLabel,
        isLink: true,
        custom_click_track_value: clicktrack_Pagespecific_reserve,
        // onClick: () => handleReserveBtnClick(),
      };
      return footerlinks;
    }
    return null;
  };

  const getFooterLinks = (restaurantUrl: string) => {
    const clicktrack_Pagespecific_details =
      currentPage === CITYPAGE
        ? `${CITY_FEATURE_SECTION}|${CITY_FEATURE_RESTAURANT_DETAILS}|${EXTERNAL_LINK}`
        : `${EARN_REDEEM_CONDENSED_FEATURE}|${EARN_REDEEM_CONDENSED_VIEWDETAILS_CTA}|${INTERNAL_LINK}`;
    if (restaurantUrl) {
      const footerlinks = {
        href: restaurantUrl,
        target: '_blank',
        rel: 'noreferrer',
        className: 'm-button-s m-button-primary custom_click_track reserve-cta-button',
        text: props?.restaurantsDetailsCtaLabel,
        isLink: false,
        ratesClickTrackValue: clicktrack_Pagespecific_details,
        // onClick: () => window.open(`${restaurantUrl}.mi`, '_blank'),
      };
      return footerlinks;
    }
    return null;
  };

  const getOutletIcon = (restaurant: any) => {
    iconTitle = getTitleIconOutlet(restaurant?.basicInformation);
    return iconTitle;
  };

  const getOutletImages = (restaurant: any) => {
    const images: {
      defaultImageUrl: string;
      isPrimaryArchitectural: boolean;
      isPrimaryServices: boolean;
      isSecondaryArchitectural: boolean;
      isSecondaryServices: boolean;
    } = {
      defaultImageUrl: '',
      isPrimaryArchitectural: false,
      isPrimaryServices: false,
      isSecondaryArchitectural: false,
      isSecondaryServices: false,
    };
    let defaultImageUrl = '';

    const outletImages: any = [];
    restaurant?.node?.media?.imageConnection?.edges.forEach((element: any) => {
      if (element?.node?.imageUrls?.wideHorizontal) {
        defaultImageUrl = `${IMAGE_URL_DOMAIN}${element?.node?.imageUrls?.wideHorizontal}?downsize=504:*`;
        images['defaultImageUrl'] = defaultImageUrl;
        images['isPrimaryArchitectural'] = element?.node?.isPrimaryArchitectural;
        images['isPrimaryServices'] = element?.node?.isPrimaryServices;
        images['isSecondaryArchitectural'] = element?.node?.isSecondaryArchitectural;
        images['isSecondaryServices'] = element?.node?.isSecondaryServices;
        outletImages.push({ ...images });
      }
    });
    const sortedImages = outletImages?.sort(function (a: any, b: any) {
      return (
        Number(b?.isPrimaryArchitectural) - Number(a?.isPrimaryArchitectural) ||
        Number(b?.isPrimaryServices) - Number(a?.isPrimaryServices) ||
        Number(b?.isSecondaryArchitectural) - Number(a?.isSecondaryArchitectural) ||
        Number(b?.isSecondaryServices) - Number(a?.isSecondaryServices)
      );
    });

    return props?.variation === 'culinaryView' ? sortedImages.slice(0, 5) : sortedImages;
  };

  const addHotelName = (data: any) => {
    if (data) {
      const newData = JSON?.parse(JSON.stringify(data));
      newData?.propertiesByIds?.forEach((item: any) => {
        const hotelName = item?.basicInformation?.displayName;
        item?.restaurants?.edges?.forEach((restItem: any) => {
          restItem.node.hotelName = hotelName;
        });
      });
      return newData;
    }
  };

  useEffect(() => {
    if (props?.trackingProperties?.impressionTrack) trackImpression(props?.trackingProperties || {}, 'Featured Outlet');
  }, [props?.trackingProperties]);

  useEffect(() => {
    let results: any = null;
    let count = 0;
    if (error && !data) {
      const errorCode = Number(getHttpStatusCode(error));
      const errorMessage = getErrorMessage(errorCode);
      if (errorCode) setErrorState(errorCode, errorMessage);
      else setErrorState(0, 'apiError');
    } else {
      setErrorState(0, '');
      // Function to add "name" key to each "node" with the value of "hotelName"
      const updatedData = addHotelName(data);
      results = updatedData?.propertiesByIds
        ?.map((res: any) => res?.restaurants?.edges)
        .reduce((acc: any, arr: any) => acc.concat(arr), []);

      filteredArray = results;

      if (filteredArray?.length > 10) {
        filteredArray = filteredArray?.slice(0, 9);
      }
      filteredArray?.forEach((res: any) => {
        const isBannerAvlb = !!res?.node?.media?.imageConnection && !!res?.node?.media?.imageConnection?.edges?.length;
        const isDescAvlb = !!res?.node?.basicInformation?.description?.length;
        const isNameAvlb = !!res?.node?.basicInformation?.name?.length;
        const isFallbackImg = !!props?.dynamicMedia;
        const isFallbackDesc = !!getFallbackDescription(
          props?.firstshortdescription,
          props?.secondshortdescription,
          res
        );
        const bannerOrFallback = isBannerAvlb === true || isFallbackImg === true;
        const descriptionOrFallback = isDescAvlb === true || isFallbackDesc === true;
        const errorCheck = bannerOrFallback === false || descriptionOrFallback === false || isNameAvlb === false;

        if (!errorCheck) {
          if (res?.node?.media?.imageConnection?.total > 1) {
            let countEachNodeImages = 0;
            res?.node?.media?.imageConnection?.edges?.forEach((res: any) => {
              if (res?.node?.imageUrls?.wideHorizontal) {
                countEachNodeImages++;
              }
            });
            if (countEachNodeImages > 1) {
              count++;
            }
          }
          const restaurantDetailsUrl = getRestaurantDetailsUrl(res);
          customCssClass = res?.node?.hotelName
            ? props?.styleclass + ' ' + 'trimmed-property-description'
            : props?.styleclass || '';
          if (res?.node?.basicInformation?.name?.length > outletNameLimit)
            customCssClass = customCssClass + ' ' + 'overridden-property-card-style';
          const PropertyCardProps = {
            titleDetails: {
              title: res?.node?.basicInformation?.name,
              titleAriaLabel: res?.node?.basicInformation?.name,
            },
            eyebrow: res?.node?.diningExperience?.cuisines[0] || '',
            description: getOutletTitle(res?.node?.basicInformation?.shortDescription, res),
            carouselDotsPosition: 'center',
            images:
              res?.node?.media?.imageConnection?.total > 0
                ? getOutletImages(res)
                : [{ defaultImageUrl: props?.dynamicMedia?.assetPath }],
            tertiaryLinkDetails: getTertiaryLinks(res),
            footerLinkDetails: getFooterLinks(restaurantDetailsUrl),
            overlayIcon:
              res?.node?.media?.imageConnection && res?.node?.media?.imageConnection?.total <= 0 ? props?.iconPath : '',
            overlayText:
              res?.node?.media?.imageConnection && res?.node?.media?.imageConnection?.total <= 0
                ? props?.overlayText
                : '',
            iconTitle: getOutletIcon(res?.node),
            showIcon: true,
            address: getOutletShortAddress(res?.node?.contactInfo),
            styleClass: customCssClass,
            propertyName: res?.node?.hotelName
              ?.toLowerCase()
              .split(' ')
              .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            iconPathDining: props?.iconPath,
            iconPathBar: props?.iconPathBar,
          };
          finalArr.push(PropertyCardProps);
        }
      });
      setCarousalCount(count);
      setRestaurantList(finalArr);
    }
    if (props?.isAuthorMode) {
      finalArr = [];
      let countMock = 0;
      let slicedData;
      if (searchResultsData?.length > 10) {
        slicedData = searchResultsData?.slice(0, 9);
      } else {
        slicedData = searchResultsData;
      }
      slicedData.forEach((res: any) => {
        if (res?.node?.media?.imageConnection?.total > 1) {
          let countEachNodeImages = 0;
          res?.node?.media?.imageConnection?.edges?.forEach((res: any) => {
            if (res?.node?.imageUrls?.wideHorizontal) {
              countEachNodeImages++;
            }
          });
          if (countEachNodeImages > 1) {
            countMock++;
          }
        }

        customCssClass = res?.node?.hotelName
          ? props?.styleclass + ' ' + 'trimmed-property-description'
          : props?.styleclass || '';
        if (res?.node?.basicInformation?.name?.length > outletNameLimit)
          customCssClass = customCssClass + ' ' + 'overridden-property-card-style';
        const PropertyCardProps = {
          titleDetails: {
            title: res?.node?.basicInformation?.name,
            titleAriaLabel: res?.node?.basicInformation?.name,
          },
          eyebrow: res?.node?.diningExperience?.cuisines[0] || '',
          description: getOutletTitle(res?.node?.basicInformation?.shortDescription, res),
          carouselDotsPosition: 'center',
          images:
            res?.node?.media?.imageConnection?.total > 0
              ? getOutletImages(res)
              : [{ defaultImageUrl: props?.dynamicMedia?.assetPath }],
          tertiaryLinkDetails: getTertiaryLinks(res),
          footerLinkDetails: getFooterLinks(res),
          overlayIcon:
            res?.node?.media?.imageConnection && res?.node?.media?.imageConnection?.total <= 0 ? props?.iconPath : '',
          overlayText:
            res?.node?.media?.imageConnection && res?.node?.media?.imageConnection?.total <= 0
              ? props?.overlayText
              : '',
          iconTitle: getOutletIcon(res?.node),
          showIcon: true,
          address: getOutletShortAddress(res?.node?.contactInfo),
          styleClass: customCssClass,
          propertyName: res?.node?.hotelName
            ?.toLowerCase()
            .split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
          iconPathDining: props?.iconPath,
          iconPathBar: props?.iconPathBar,
        };
        finalArr.push(PropertyCardProps);
      });
      results = finalArr?.filter((res: any) => res !== undefined);
      setRestaurantList(results);
      setCarousalCount(countMock);
    }
  }, [data]);

  return restaurantList && restaurantList?.length ? (
    <StyledFeaturedOutlets
      data-testid="featuredoutlet"
      data-component-name="o-rnb-featuredoutlet"
      variation={props?.variation}
      className="featured-outlet"
    >
      <div
        className={clsx(
          isMobileViewPort
            ? props?.variation === 'culinaryView'
              ? 'container py-3'
              : 'container'
            : props?.variation === 'culinaryView'
            ? 'container py-3 px-0'
            : 'container py-5',
          props?.variation === 'culinaryView' ? 'culinary-view-container' : ''
        )}
      >
        <div className="d-flex justify-content-between align-items-baseline">
          <Heading
            titleText={props?.title}
            variation={Types.headingType.title}
            fontSize={Types.size.small}
            customClass={clsx('text-left', props?.variation === 'culinaryView' ? 'm-0 pt-0' : 'pt-5')}
          />
          {restaurantList?.length > 2 && props?.variation !== 'culinaryView' && (
            <Button
              isLink={props?.openInNewTab}
              href={props?.linkUrl}
              target={props?.openInNewTab ? '_blank' : '_self'}
              className="m-link-tertiary-button pt-4 mb-2"
              children={props?.viewAllRestaurantsLabel}
              custom_click_track_value={`${CITY_FEATURE_SECTION}|${CITY_FEATURED_VIEWALL}|${INTERNAL_LINK}`}
              trackingProperties={props?.trackingProperties}
            />
          )}
        </div>

        <div id={componentId} className="glideOutlet" ref={glideRefOutlet}>
          <div className="glide__track" data-glide-el="track">
            <ul
              className={clsx(
                'glide__slides d-flex p-0 mb-0 outer-glide',
                props?.variation === 'culinaryView' ? 'pb-3' : 'pb-4'
              )}
            >
              {restaurantList?.map((res: any, index: number) => {
                return (
                  <li
                    className="glide__slide outer-glide"
                    key={`child-glide-outlet-li-${index}`}
                    data-glide-index={index}
                  >
                    <StyledPropertyCardContainer>
                      <PropertyCard
                        {...res}
                        parentGlide={parentGlide.current}
                        innerGlideList={innerGlideList}
                        glideClass={`child-glide-outlet-${index}`}
                        key={`child-glide-outlet-${index}`}
                        slideIndex={index + 1}
                        variation={props?.variation}
                      />
                    </StyledPropertyCardContainer>
                  </li>
                );
              })}
            </ul>
            {restaurantList?.length > 1 && (
              <div
                className={clsx(
                  'd-flex align-items-center justify-content-center controls_wrapper',
                  props?.variation === 'culinaryView' ? '' : ' pb-5'
                )}
                data-testid={componentId}
              >
                <div className="carouselControlType2">
                  <div className="glide__arrows" data-glide-el="controls">
                    <button
                      className={`glide__arrow--left left-arrow ${
                        isFirstSlide && direction === 'rtl' ? 'disabled-rtl-arrow' : 'enabled-rtl-arrow'
                      }  ${direction === 'rtl' ? 'ml-0 ml-sm-4' : ''}`}
                      aria-label={props?.previousButtonLabel}
                      data-content={props?.previousButtonLabel}
                      id={`${componentId}_left_arrow`}
                      data-glide-dir={`${direction === 'rtl' ? '>' : '<'}`}
                    >
                      <span className="icon-arrow-left"></span>
                    </button>
                  </div>
                  <div data-glide-el="controls[nav]">
                    {restaurantList?.map((_, index: number) => {
                      return (
                        <button
                          className=""
                          data-glide-dir={`=${index}`}
                          key={`featured-outlet-controls-${index}`}
                          aria-label={`Slide ${index}`}
                        ></button>
                      );
                    })}
                  </div>
                  <div className="glide__arrows" data-glide-el="controls">
                    <button
                      className={` glide__arrow--right right-arrow ${
                        isLastSlide && direction === 'rtl' ? 'disabled-rtl-arrow' : 'enabled-rtl-arrow'
                      } ${direction === 'rtl' ? 'mr-0 mr-sm-4' : ''} ${
                        currentLocale === 'ru-RU' ? 'ru-locale-specific' : ''
                      }`}
                      data-glide-dir={`${direction === 'rtl' ? '<' : '>'}`}
                      aria-label={props?.nextButtonLabel}
                      id={`${componentId}_right_arrow`}
                      data-content={props?.nextButtonLabel}
                    >
                      <span className="icon-arrow-right"></span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledFeaturedOutlets>
  ) : null;
};

export const FeaturedOutletsConfig = {
  emptyLabel: 'featuredoutlet',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/featuredoutlet`,
};

export const FeaturedOutletsEditable = (props: any) => (
  <EditableComponent config={FeaturedOutletsConfig} {...props}>
    <FeaturedOutlets {...props} />
  </EditableComponent>
);
