/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import {
  CampaignOffersCardProps,
  FacetsType,
  CampaignDataListType,
  OffersOverlayDataType,
  orderList,
  SelectedOfferType,
  AssociatedOutletType,
  AssociatedPropertyType,
} from './CampaignOffersCardContainer.types';
import { OffersCard } from '../../molecules/OffersCard';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Heading, Icon, Link, Modal, RichText, Types } from '@marriott/mi-ui-library';
import { ModalHeaderGeneric } from '../../molecules/ModalHeaderGeneric';
import {
  IMAGE_URL_DOMAIN,
  offerCard,
  offerOverlay,
  offerType,
  outletprimaryType,
  outletsecondaryType,
} from '../../modules/utils/constants/constants';
import { PaginationPanel } from '../../molecules/Pagination';
import clsx from 'clsx';
import {
  StyledCampaignContainer,
  StyledErrorContainer,
  StyledLoader,
  StyledOffersCardContainer,
  StyledCampaignContainerLoader,
  HideGlobalHeader,
} from './CampaignOffersCardContainer.styles';
import OffersOverlayContent from './OffersOverlayContent/OffersOverlayContent';
import { getDirection, getProcessedFilterData, useGetBreakpoint } from '../../modules/utils/helpers';
import searchFilterStore, { searchFilterState } from '../../modules/store/store';
import { searchResultsState, useStore } from '../../modules/store/searchResultsStore';
import { SearchAllFilters } from '../SearchResults/SearchAllFilters';
import { PageParamsContext } from '../../modules/context/PageContext';
import { TRACKING_CONSTANT } from '../../modules/utils/constants';
import { useApolloClient } from '@apollo/client';
import {
  campaignMBOPOfferDetails,
  campaignCHUBOfferDetails,
  campaignOffersListDetails,
} from '@marriott/mi-rnb-graphql';
import Cookies from 'js-cookie';
import { MBOPOfferDetailsMock as overlayMock } from './__mocks__/overlayMock';
import { campaignDetailsStore } from '../../modules/store/campaignDetailsStore';
import campaignOfferDetailsMock from './__mocks__/CampaignOfferDetailsMock.json';
import { terms } from '../SearchResults/SearchAllFilters/SearchAllFilters.types';

const { NEXT_PUBLIC_AEM_SITE } = process.env;
const {
  CAMPAIGN_PAGE,
  CAMPAIGN_PAGE_RESERVE_BUTTON,
  CAMPAIGN_PAGE_OVERLAY_VENUE_DETAILS,
  EXTERNAL_LINK,
  INTERNAL_LINK,
  CAMPAIGN_PAGE_PAGINATION,
} = TRACKING_CONSTANT;

const ErrorBlock = (props: { styleClass?: string; errorMessage: string }) => {
  const { styleClass, errorMessage } = props;

  return (
    <div className={styleClass}>
      <div className="m-message-content-wrap">
        <div className="m-message-content">
          <RichText text={errorMessage} componentId="campaign-error-message" />
        </div>
      </div>
    </div>
  );
};

export const CampaignOffersCardContainer = (props: CampaignOffersCardProps) => {
  const [openOffersOverlay, setOpenOffersOverlay] = useState(false);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const [isTabletViewPort, setIsTabletViewPort] = useState(useGetBreakpoint() === 'tablet');
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const PageSize = 9;
  const paginationChange = useStore((state: searchResultsState) => state.paginationChange);
  const pageNumber = useStore((state: searchResultsState) => state.pageNumber);
  const setPaginationChange = useStore((state: searchResultsState) => state.setPaginationChange);
  const setPageNumber = useStore((state: searchResultsState) => state.setPageNumber);
  const term = campaignDetailsStore(state => state.terms);
  const setTerms = campaignDetailsStore(state => state.setTerms);
  const queryParam = useStore((state: searchResultsState) => state.queryParam);
  const setQueryParam = useStore((state: searchResultsState) => state.setQueryParam);
  let filterUpdateData = useStore((state: searchResultsState) => state.facetsData);
  const setFacetsData = useStore((state: searchResultsState) => state.setFacetsData);
  const cuisine = searchFilterStore((state: searchFilterState) => state.cuisine);
  const city = searchFilterStore((state: searchFilterState) => state.city);
  const country = searchFilterStore((state: searchFilterState) => state.country);
  const cuisineSelection = searchFilterStore((state: searchFilterState) => state.cuisineSelection);
  const citySelection = searchFilterStore((state: searchFilterState) => state.citySelection);
  const countrySelection = searchFilterStore((state: searchFilterState) => state.countrySelection);
  const { currentPage: currentPageFromContext, currentLocale } = useContext(PageParamsContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [offerDetailsData, setOfferDetailsData] = useState<any>();
  const [selectedOfferType, setSelectedOfferType] = useState('');
  const [selectedOffer, setSelectedOffer] = useState<SelectedOfferType>({});
  const [loading, setIsLoading] = useState(false);
  const campaignData = campaignDetailsStore(state => state.campaignData);
  const campaignsLoading = campaignDetailsStore(state => state.campaignsLoading);
  const campaignDataError = campaignDetailsStore(state => state.campaignDataError);
  const campaignOffersList = campaignDetailsStore(state => state.campaignOffersList);
  const campaignOffersListError = campaignDetailsStore(state => state.campaignOffersListError);
  const setCampaignOfferByIdDetailsError = campaignDetailsStore(state => state.setCampaignOfferByIdDetailsError);
  const campaignOfferByIdDetailsError = campaignDetailsStore(state => state.campaignOfferByIdDetailsError);
  const campaignId = campaignDetailsStore(state => state.campaignId);
  const setCampaignOffersList = campaignDetailsStore(state => state.setCampaignOffersList);
  const TotalPages = Math.ceil(campaignOffersList?.total / PageSize);
  const currentTimestamp = Date.now();
  const currentDateTimeStamp = new Date(currentTimestamp).getTime();
  const updatedTerms: terms[] = [];
  const isDirectionRTL = getDirection() === 'rtl';
  const [hideHeader, setHideHeader] = useState(false);
  const scrollPosition = campaignDetailsStore(state => state.scrollPosition);
  const setScrollPosition = campaignDetailsStore(state => state.setScrollPosition);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pageContext = useContext(createContext<any>({}));
  const requestId = useMemo(() => {
    return pageContext.requestId ? pageContext.requestId : `${Date.now()}`;
  }, [pageContext]);
  const sessionID = Cookies.get('sessionID');

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsTabletViewPort(useGetBreakpoint() === 'tablet');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    function handleScroll() {
      const scrollPos = window?.scrollY;
      if (scrollPosition !== scrollPos) setHideHeader(false);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPosition]);

  useEffect(() => {
    setQueryParam(window.location.search);
  }, []);

  useEffect(() => {
    if (props?.isAuthorMode) {
      setOfferDetailsData(overlayMock.data.offersSearch.edges[0].node);
      setFacetsData(campaignOfferDetailsMock?.data?.diningOfferCampaignDetails?.OfferDetail?.facets);
    }
  }, [props?.isAuthorMode]);

  useEffect(() => {
    setFacetsPayload();
  }, [queryParam, pageNumber]);

  useEffect(() => {
    if (!paginationChange) {
      setCurrentPage(1);
      setPageNumber(1);
      calculateIndex(1);
    } else {
      calculateIndex(pageNumber);
    }
  }, [pageNumber, campaignOffersList]);

  // UXL call to get Search Results
  const campaignOffersListDetailsClient = useApolloClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function getCampaignOffersList(inputValues: any) {
    setCampaignOffersList(null, false, true);
    setCampaignOfferByIdDetailsError(false);
    let campaignOffers = null;
    try {
      const { data } = await campaignOffersListDetailsClient.query({
        fetchPolicy: 'no-cache',
        query: campaignOffersListDetails,
        errorPolicy: 'all',
        ...inputValues,
      });
      if (data && data?.diningOfferCampaignDetails) campaignOffers = data?.diningOfferCampaignDetails;
    } catch (error) {
      setCampaignOffersList(null, true, false);
    } finally {
      if (campaignOffers?.OfferDetail?.edges?.length > 0) {
        filterUpdateData = campaignOffers?.OfferDetail;
        setCampaignOffersList(campaignOffers?.OfferDetail, false, false);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const facets = campaignOffers?.OfferDetail?.facets?.map((facet: any) => {
          return {
            ...facet,
            buckets: facet?.buckets?.filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (bucket: any) => bucket?.label !== '' && bucket?.label !== null && bucket?.label !== undefined
            ),
          };
        });
        setFacetsData(facets);
        if (filterUpdateData?.facets?.length > 0) {
          const query = new URLSearchParams(queryParam);
          updateStoreData('cuisine', 'cuisine', cuisineSelection, query);
          updateStoreData('city', 'city', citySelection, query);
          updateStoreData('country', 'country', countrySelection, query);
        }
      } else setCampaignOffersList(null, true, false);
    }
  }

  useEffect(() => {
    if (!props?.isAuthorMode && campaignId && !campaignDataError && campaignData?.OfferDetail?.total > 0) {
      getCampaignOffersList({
        variables: {
          input: {
            campaignId: campaignId,
            limit: PageSize,
            offset: pageNumber === 1 ? 0 : (pageNumber - 1) * PageSize,
            facets: {
              terms: term,
            },
          },
        },

        context: {
          headers: {
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });
    }
  }, [term, pageNumber, campaignId]);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    const headerAccBtn = document.getElementsByClassName('m-header standard');
    section?.scrollIntoView(true);
    if (headerAccBtn?.length > 0) {
      setHideHeader(true);
      setScrollPosition(window?.scrollY);
    } else {
      setHideHeader(false);
      setScrollPosition(0);
    }
  };

  const onPageItemClick = (page: number): void => {
    setPaginationChange(true);
    setCurrentPage(page);
    setPageNumber(page);
    calculateIndex(page);
    scrollToSection('offerCardContainer');
  };

  const calculateIndex = (page: number) => {
    const startInd = (page - 1) * PageSize;
    const endInd = startInd + PageSize;
    setStartIndex(startInd);
    setEndIndex(endInd);
    if (page === TotalPages) {
      setEndIndex(campaignOffersList?.total);
    }
  };

  const outletNameObj = (offer: CampaignDataListType) => {
    const titleDetails = {
      title: offer?.outletName,
      titleAriaLabel: offer?.outletName,
    };
    return titleDetails;
  };

  const getOfferImages = (offer: CampaignDataListType) => {
    const images = [];
    offer?.image?.imageUrls?.classicHorizontal
      ? images.push({ defaultImageUrl: `${IMAGE_URL_DOMAIN}${offer?.image?.imageUrls?.classicHorizontal}` })
      : images.push({ defaultImageUrl: props?.dynamicMedia?.assetPath });
    return images;
  };

  const showOfferOverlay = (offer: CampaignDataListType) => {
    setOpenOffersOverlay(true);

    if (offer?.type) setSelectedOfferType(offer?.type);
    else setSelectedOfferType('');

    if (props?.isAuthorMode) setIsLoading(false);
    else {
      if (offer?.type?.toLowerCase() === offerType.MbopOffer.toLowerCase()) {
        setIsLoading(true);
        getMBOPOfferDetails(offer, offer?.primaryType);
      } else if (offer?.type?.toLowerCase() === offerType.CHUBOffer.toLowerCase()) {
        setIsLoading(true);
        getCHUBOfferDetails(offer);
      }
    }
  };

  const getTertiaryLinks = (offer: CampaignDataListType) => {
    const tertiaryLinks = {
      placeLinkOnTopSection: false,
      rel: 'noreferrer',
      className: 'm-link-tertiary-button custom_click_track p-0',
      buttonCopy: props?.viewDetailLabelText,
      isLink: false,
      onClick: () => showOfferOverlay(offer),
    };
    return tertiaryLinks;
  };

  const getFooterLinks = (offer: CampaignDataListType) => {
    if (offer?.reservationURL) {
      const footerlinks = {
        href: offer?.reservationURL,
        target: props?.reserveCtaOpenNewTab ? '_blank' : '_self',
        ratesClickTrackValue: `${CAMPAIGN_PAGE}|${CAMPAIGN_PAGE_RESERVE_BUTTON} on ${offer?.outletName}|${EXTERNAL_LINK}`,
        rel: 'noreferrer',
        className: 'm-button-s m-button-primary custom_click_track',
        text: props?.reserveCtaLabelText,
        isLink: false,
      };
      return footerlinks;
    }
    return;
  };

  const getIconPath = (offer: CampaignDataListType | OffersOverlayDataType, location: string) => {
    const iconPath =
      offer?.primaryType?.toLowerCase() === outletprimaryType
        ? location === offerCard
          ? props?.restaurantDetailsIconPath
          : props?.overlayRestaurantIconPath
        : offer?.primaryType?.toLowerCase() === outletsecondaryType
        ? location === offerCard
          ? props?.barDetailsIconPath
          : props?.overlayBarIconPath
        : 'icon-dining';
    return iconPath;
  };

  const getOutletDetailsPageUrl = (propertyId: string, propertyName: string, outletId: string, outletName: string) => {
    const locale = currentLocale?.toLowerCase();
    if (!!propertyId && !!outletId) {
      return (
        `/${locale}/dining/restaurant-bar/` +
        propertyId?.toLowerCase() +
        '-' +
        propertyName
          .replace(/[^A-Z0-9]+/gi, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase() +
        '/' +
        outletId +
        '-' +
        outletName
          .replace(/[^A-Z0-9]+/gi, '-')
          .replace(/^-+|-+$/g, '')
          .toLowerCase() +
        '.mi'
      );
    } else return '';
  };

  const actions = [];
  if (selectedOffer?.viewDetailsUrl)
    actions.push(
      <Button
        isLink={true}
        href={selectedOffer?.viewDetailsUrl}
        className="m-button-secondary m-button-m text-center"
        target={props?.restaurantDetailsOpenNewTab ? '_blank' : '_self'}
        ariaLabel={props?.restaurantDetailsLabel}
        trackingProperties={props?.trackingProperties}
        custom_click_track_value={`${CAMPAIGN_PAGE}|${CAMPAIGN_PAGE_OVERLAY_VENUE_DETAILS}|${INTERNAL_LINK}`}
      >
        {props?.restaurantDetailsLabel}
      </Button>
    );

  if (selectedOffer?.reserveUrl)
    actions.push(
      <Link
        text={props?.reserveCtaLabelText || ''}
        linkClassName="m-button-primary m-button-m text-center"
        ariaLabel={props?.reserveCtaLabelText || ''}
        linkHref={selectedOffer?.reserveUrl}
        target={props?.reserveCtaOpenNewTab ? '_blank' : '_self'}
      />
    );

  const setFacetsPayload = () => {
    const query = new URLSearchParams(queryParam);
    const routeKeys: string[] = [];
    query.forEach((_value, key) => {
      routeKeys.push(key);
    });
    const filteredArray = routeKeys.filter((value: string) => {
      return props?.filtersOrderList?.some((obj: orderList) => obj?.filterName === value);
    });
    cuisineSelection([]);
    citySelection([]);
    countrySelection([]);

    if (filteredArray.length > 0) {
      filteredArray.forEach((filter: string) => {
        storeUpdater(filter);
      });
    } else setTerms([]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateQueryParams = (filterData: FacetsType[], selectedVal: any, facetsVal: terms) => {
    if (filterData?.length > 0) {
      const getSelectedFilter = filterData.filter((element: FacetsType) => selectedVal.includes(element.label));
      const filterLabels = getSelectedFilter?.map((filterName: FacetsType) => filterName.label);
      if (facetsVal.type === 'city') {
        facetsVal['dimensions'] = filterLabels?.map((dimension: any) => {
          if (dimension?.includes(' -')) {
            const updatedDimension = dimension?.replace(' -', ',');
            return updatedDimension;
          } else return dimension;
        });
      } else facetsVal['dimensions'] = filterLabels;
    } else {
      if (facetsVal.type === 'city') {
        facetsVal['dimensions'] = selectedVal?.map((dimension: any) => {
          if (dimension?.includes(' -')) {
            const updatedDimension = dimension?.replace(' -', ',');
            return updatedDimension;
          } else return dimension;
        });
      } else facetsVal['dimensions'] = [...selectedVal];
    }
    updatedTerms.push(facetsVal);
    setTerms(updatedTerms);
  };

  const storeUpdater = (filter: string) => {
    const query = new URLSearchParams(window.location.search);
    const facetsVal = { type: '', dimensions: [] };
    const values: string | null = query?.get(filter) ? query?.get(filter) : '';
    let selectedVal: string[] | undefined = [];
    selectedVal = values?.split(',');
    facetsVal.type = filter;
    switch (facetsVal.type) {
      case 'cuisine':
        generateQueryParams(cuisine, selectedVal, facetsVal);
        break;

      case 'city':
        generateQueryParams(city, selectedVal, facetsVal);
        break;

      case 'country':
        generateQueryParams(country, selectedVal, facetsVal);
        break;
    }
  };

  const updateStoreData = (
    name: string,
    filterName: string,
    storeFilterUpdater: (selectedFilter: FacetsType[]) => void,
    query: URLSearchParams
  ) => {
    const filterData = getProcessedFilterData(
      filterUpdateData?.facets,
      name,
      props,
      currentLocale ? currentLocale : 'en-US'
    );
    const filterValues: string | null = query.get(filterName) ? query.get(filterName) : '';
    if (filterValues) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let selectedFilter: any = [];
      selectedFilter = filterValues.split(',');
      const selectedFilterArr: FacetsType[] = [];
      filterData.forEach((item: FacetsType) => {
        if (selectedFilter.includes(item.label)) selectedFilterArr.push(item);
      });
      storeFilterUpdater(selectedFilterArr);
    }
  };

  const mbopOfferDetailsClient = useApolloClient();
  const getMBOPOfferDetails = async (offer: CampaignDataListType, offerPrimaryType?: string) => {
    try {
      const { data } = await mbopOfferDetailsClient.query({
        query: campaignMBOPOfferDetails,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          input: {
            queries: [
              {
                id: 'offers',
                values: [offer?.id],
              },
              {
                id: 'outlets',
                values: [offer?.outletEpicId?.id?.split('.')[1]],
              },
            ],
          },
        },
        context: {
          headers: {
            // Any header, including x-request-id, can be passed in args with query.
            // If you don't pass it in the authLink will generate a random ID.
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });

      if (
        data?.offersSearch?.edges &&
        data?.offersSearch?.edges?.length > 0 &&
        data?.offersSearch?.edges[0]?.node?.title &&
        data?.offersSearch?.edges[0]?.node?.description
      ) {
        if (
          (data?.offersSearch?.edges[0]?.node?.associatedOutlets &&
            data?.offersSearch?.edges[0]?.node?.associatedOutlets?.length > 0 &&
            data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets?.length > 0) ||
          (data?.offersSearch?.edges[0]?.node?.associatedProperties &&
            data?.offersSearch?.edges[0]?.node?.associatedProperties?.length > 0)
        ) {
          // const selectedOfferOutlet = data?.offer?.associatedOutlets[0]?.outlets?.filter(
          //   (outlet: AssociatedOutletType) => outlet?.id === offer?.outletEpicId?.id?.split('.')[1]
          // );
          const selectedOfferProperty = data?.offersSearch?.edges[0]?.node?.associatedProperties?.filter(
            (property: AssociatedPropertyType) => property?.property?.id === offer?.outletEpicId?.id?.split('.')[0]
          );
          const updatedData = {
            ...data,
            offersSearch: {
              ...data?.offersSearch,
              edges: [
                {
                  node: {
                    ...data?.offersSearch?.edges[0]?.node,
                    associatedOutlets: [
                      {
                        outlets: data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets,
                        property: {
                          ...data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.property,
                          basicInformation: selectedOfferProperty[0]?.property?.basicInformation,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          };
          setOfferDetailsData(updatedData?.offersSearch?.edges[0]?.node);
          const offerDetailsHeaderData = {
            cuisine: data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets[0]?.cuisines[0] || '',
            primaryType: offerPrimaryType || '',
            outletName: data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets[0]?.name || '',
            reserveUrl: data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets[0]?.reservationURL || '',
            viewDetailsUrl: getOutletDetailsPageUrl(
              selectedOfferProperty[0]?.property?.id,
              selectedOfferProperty[0]?.property?.basicInformation?.name,
              data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets[0]?.id,
              data?.offersSearch?.edges[0]?.node?.associatedOutlets[0]?.outlets[0]?.name
            ),
          };
          setSelectedOffer(offerDetailsHeaderData);
        } else {
          setOfferDetailsData(data?.offersSearch?.edges[0]?.node);
          setSelectedOffer({});
        }
        setCampaignOfferByIdDetailsError(false);
      } else {
        setOfferDetailsData(undefined);
        setOpenOffersOverlay(false);
        setCampaignOfferByIdDetailsError(true);
        setSelectedOffer({});
        scrollToSection('offerCardContainer');
      }
    } catch (e) {
      setOfferDetailsData(undefined);
      setOpenOffersOverlay(false);
      setCampaignOfferByIdDetailsError(true);
      setSelectedOffer({});
      scrollToSection('offerCardContainer');
    } finally {
      setIsLoading(false);
    }
  };

  const chubOfferDetailsClient = useApolloClient();
  const getCHUBOfferDetails = async (offer: CampaignDataListType) => {
    try {
      const { data } = await chubOfferDetailsClient.query({
        query: campaignCHUBOfferDetails,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          input: {
            offerId: offer?.guid,
          },
          filter: {
            propertyId: offer?.propertyId?.id,
            outletId: offer?.outletEpicId?.id?.split('.')[1],
          },
        },
        context: {
          headers: {
            // Any header, including x-request-id, can be passed in args with query.
            // If you don't pass it in the authLink will generate a random ID.
            'accept-language': currentLocale?.replace('_', '-') ?? 'en-US',
            'x-request-id': requestId,
            'x-b3-traceId': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });

      if (data?.diningOfferbyId && data?.diningOfferbyId?.name && data?.diningOfferbyId?.description) {
        setOfferDetailsData(data?.diningOfferbyId);
        setCampaignOfferByIdDetailsError(false);

        if (
          data?.diningOfferbyId?.participatingOutlets &&
          data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails?.length > 0
        ) {
          const offerDetailsHeaderData = {
            cuisine:
              data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails[0]?.restaurant?.diningExperience
                ?.cuisines?.length > 0
                ? data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails[0]?.restaurant?.diningExperience
                    ?.cuisines[0]
                : '',
            primaryType:
              data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails[0]?.restaurant?.primaryType || '',
            outletName:
              data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails[0]?.restaurant?.basicInformation
                ?.name || '',
            reserveUrl:
              data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails[0]?.restaurant?.basicInformation
                ?.reservationURL || '',
            viewDetailsUrl: getOutletDetailsPageUrl(
              offer?.propertyId?.id || '',
              data?.diningOfferbyId?.participatingOutlets[0]?.property?.basicInformation?.name,
              offer?.outletEpicId?.id?.split('.')[1] || '',
              data?.diningOfferbyId?.participatingOutlets[0]?.restaurantDetails[0]?.restaurant?.basicInformation?.name
            ),
          };
          setSelectedOffer(offerDetailsHeaderData);
        }
      } else {
        setOfferDetailsData(undefined);
        setOpenOffersOverlay(false);
        setCampaignOfferByIdDetailsError(true);
        setSelectedOffer({});
        scrollToSection('offerCardContainer');
      }
    } catch (e) {
      setOfferDetailsData(undefined);
      setOpenOffersOverlay(false);
      setCampaignOfferByIdDetailsError(true);
      setSelectedOffer({});
      scrollToSection('offerCardContainer');
    } finally {
      setIsLoading(false);
    }
  };

  return campaignOffersListError || (!campaignDataError && campaignData?.OfferDetail?.total === 0) ? (
    <StyledErrorContainer className={clsx('container')} data-testid="offers-list-error">
      {hideHeader && <HideGlobalHeader />}
      <ErrorBlock styleClass="m-message-inline warning" errorMessage={props?.offerErrorMsg || ''} />
    </StyledErrorContainer>
  ) : campaignsLoading ? (
    <StyledCampaignContainerLoader
      className="d-flex justify-content-center align-items-center"
      data-testid="offers-list-loading"
    >
      <div className="m-spinner-m"></div>
    </StyledCampaignContainerLoader>
  ) : (
    campaignOffersList?.edges &&
    campaignOffersList?.edges?.length > 0 && (
      <StyledCampaignContainer className={clsx('container px-md-0')} id="offerCardContainer">
        {hideHeader && <HideGlobalHeader />}
        <SearchAllFilters
          {...props}
          isMobileViewPort={isMobileViewPort}
          currentPage={currentPageFromContext}
          locationLabel={props?.locationFilterLabel}
        />
        <StyledOffersCardContainer>
          <Heading
            variation={isMobileViewPort ? Types.headingType.body : Types.headingType.subtitle}
            titleText={props?.resultCountLabel?.replace(
              /{}/,
              `${startIndex + 1}-${endIndex} of ${campaignOffersList?.total ? campaignOffersList?.total : '0'}`
            )}
            fontSize={isMobileViewPort ? Types.size.small : Types.size.extraLarge}
            customClass={clsx(
              isMobileViewPort || isTabletViewPort ? 'my-3' : 'my-4',
              isDirectionRTL ? 'text-right' : 'text-left'
            )}
          ></Heading>

          {campaignOfferByIdDetailsError && (
            <div className={clsx(isMobileViewPort ? 'my-3' : 'my-4')}>
              <ErrorBlock styleClass="m-message-inline warning" errorMessage={props?.overlayErrorMsg || ''} />
            </div>
          )}

          <div className="offers-card-container">
            {campaignOffersList?.edges?.map((offer: CampaignDataListType, index: number) => (
              <OffersCard
                titleDetails={outletNameObj(offer)}
                cuisineOverlay={offer?.cuisine?.toUpperCase()}
                propertyName={offer?.propertyName}
                description={offer?.name}
                showIcon={true}
                images={getOfferImages(offer)}
                tertiaryLinkDetails={getTertiaryLinks(offer)}
                footerLinkDetails={getFooterLinks(offer)}
                iconTitle={getIconPath(offer, offerCard)}
                key={`Offer-card${index}`}
                trackingProperties={props?.trackingProperties}
              />
            ))}
          </div>
          <div className="pagination-container mt-5" data-testid="pagination-panel">
            <PaginationPanel
              currentPage={currentPage}
              totalCount={campaignOffersList?.total}
              pageSize={PageSize}
              onPageChange={(page: number): void => onPageItemClick(page)}
              clickTrackLocation={CAMPAIGN_PAGE_PAGINATION}
            />
          </div>
          {openOffersOverlay && (loading || !!offerDetailsData) && (
            <Modal
              className="offers-overlay__container"
              show={openOffersOverlay}
              handleBlur={true}
              popupOpenState={openOffersOverlay}
              allFilterPopUp={true}
              onClose={(): void => {
                setOpenOffersOverlay(false);
              }}
              data-testid="offers-overlay-modal"
            >
              {loading ? (
                <StyledLoader data-testid="offer-details-loader">
                  <div className="m-spinner-m"></div>
                </StyledLoader>
              ) : (
                <>
                  <ModalHeaderGeneric
                    className="offers-overlay__header"
                    popupHeaderOnCLoseFunc={(): void => {
                      setOpenOffersOverlay(false);
                    }}
                  >
                    <div className={clsx('d-flex flex-column', isDirectionRTL ? 'text-right' : '')}>
                      {selectedOffer?.cuisine && (
                        <span className="t-overline-normal mb-2">{selectedOffer?.cuisine}</span>
                      )}
                      {(selectedOffer?.primaryType || selectedOffer?.outletName) && (
                        <div className="d-flex flex-row align-items-center">
                          <Icon
                            iconClass={clsx(
                              'icon-decorative pt-0',
                              isDirectionRTL ? 'pl-2' : 'pr-2',
                              getIconPath(selectedOffer, offerOverlay)
                            )}
                          ></Icon>
                          {selectedOffer?.outletName && (
                            <Heading
                              variation={Types.headingType.title}
                              titleText={selectedOffer?.outletName}
                              fontSize={Types.size.small}
                              customClass={clsx(
                                `property-card__title mb-0`,
                                isDirectionRTL ? 'pl-1 pr-0' : 'pl-0 pr-1'
                              )}
                            ></Heading>
                          )}
                        </div>
                      )}
                    </div>
                  </ModalHeaderGeneric>

                  <Modal.Body className="offers-overlay__body">
                    <OffersOverlayContent
                      offerDetails={offerDetailsData}
                      {...props}
                      isMobileViewPort={isMobileViewPort}
                      offerType={selectedOfferType}
                    />
                  </Modal.Body>

                  {(selectedOffer?.reserveUrl || selectedOffer?.viewDetailsUrl) && (
                    <Modal.FooterGeneric className={clsx('d-flex offers-overlay__footer p-5')} actions={actions} />
                  )}
                </>
              )}
            </Modal>
          )}
        </StyledOffersCardContainer>
      </StyledCampaignContainer>
    )
  );
};

export const CampaignOffersCardContainerFeatureConfig = {
  emptyLabel: 'offersCardContainer',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/offersCardContainer`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CampaignOffersCardContainerEditable = (props: any) => (
  <EditableComponent config={CampaignOffersCardContainerFeatureConfig} {...props}>
    <CampaignOffersCardContainer {...props} />
  </EditableComponent>
);
