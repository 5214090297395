import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import { CustomModelClient } from '@marriott/mi-headless-utils';

import { defineComponentMapping } from '../import-components';
import { canUseDOM } from '@marriott/mi-ui-library';
import path from 'path';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import { useEffect } from 'react';
import { version as globalStylesVersion } from '@marriott/global-styles/package.json';
import { useRouter } from 'next/router';

path.resolve('./next.config.js');

declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  const { NEXT_GLOBAL_ASSET_PREFIX } = pageProps;
  const { model, resolvedUrl, isAuthorMode, apolloEnvVars = {} } = pageProps;
  const isRtl = pageProps?.model?.rtlEnable || pageProps?.rtlEnable || false; // Default to LTR if not provided
  const router = useRouter();

  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);

  useEffect(() => {
    if (isAuthorMode) {
      let remoteURL: URL | string;
      try {
        remoteURL = new URL(document.body.dataset.remoteUrl || '');
        remoteURL = remoteURL.origin;
      } catch (e) {
        remoteURL = '';
      }
      const assetUrl = `${
        process.env['NEXT_PUBLIC_ASSET_DOMAIN'] || remoteURL || 'https://www.marriott.com'
      }${NEXT_GLOBAL_ASSET_PREFIX}`;

      const globalStyle = document.createElement('link');

      globalStyle.rel = 'stylesheet';
      globalStyle.href =
        assetUrl +
        (isRtl
          ? `global-styles/${globalStylesVersion}/marriot.global.rtl.css`
          : `global-styles/${globalStylesVersion}/marriot.global.css`);
      document.head.appendChild(globalStyle);

      const { asPath } = router;
      const path = asPath.split('/').filter(Boolean);
      const lastSegment = path[path.length - 1];
      const brandCodeMatch = lastSegment?.match(/brandCode=([^&]+)/);
      const brandCode = brandCodeMatch ? brandCodeMatch[1] : '';

      if (brandCode) {
        const brandLink = document.createElement('link');
        brandLink.rel = 'stylesheet';
        brandLink.href = assetUrl + `brand-framework/brand-config.${brandCode}.css`;
        document.head.appendChild(brandLink);
      }
    }
  }, []);

  setEnvVars(apolloEnvVars);

  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;

  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode);

  return <Component {...pageProps} />;
};

export default App;
