import clsx from 'clsx';

import { Button, Text, Types } from '@marriott/mi-ui-library';
import { StyledOverviewTab } from './OverviewTab.styles';
import { useState } from 'react';
import { MaxCharCount } from '../../../modules/utils/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OverviewTab = (props: any) => {
  const [showMore, setIsShowMore] = useState(false);

  const handleOnClick = () => {
    setIsShowMore(!showMore);
  };

  // const description = filteredOutletDetails?.description?.length
  //   ? filteredOutletDetails?.description
  //   : props?.shortDescription?.length
  //   ? props?.shortDescription
  //   : '';
  let fallbackDesc = `${props?.description}`;
  fallbackDesc = fallbackDesc.replace(/\[.*?\]/, props?.outletDetails?.basicInformation?.name);
  const hotelName = props?.outletDetails?.properties?.edges[0]?.node?.property?.basicInformation?.brand?.name;

  fallbackDesc = fallbackDesc.replace(/\[.*?\]/, hotelName);

  const description = props?.outletDetails?.basicInformation?.longDescription?.length
    ? props?.outletDetails?.basicInformation?.longDescription
    : props?.outletDetails?.basicInformation?.shortDescription || fallbackDesc;
  return (
    <StyledOverviewTab>
      <Text
        customClass={clsx(
          'overview-description t-font-alt-s text-left'
          // !showMore ? 'overview-short-description' : '',
          // showMore ? 'overview-full-description' : ''
        )}
        fontSize={Types.size.small}
        data-testid="overview-description-div"
        element={Types.tags.paragraph}
        copyText={
          description.length <= MaxCharCount || showMore ? description : description.slice(0, MaxCharCount) + '[...]'
        }
      ></Text>
      {description.length > MaxCharCount ? (
        <Button
          type={Types.ButtonTypeVariation.Button}
          className="show-more-button py-1 px-0 m-0 mt-3 t-font-alt-s"
          callback={handleOnClick}
        >
          <span className={clsx('t-font-s', 'show-more-text')}>
            {showMore ? props?.seeLessLabel || '' : props?.seeMoreLabel || ''}
          </span>
          <span className={clsx('show-more-icon', showMore ? 'icon-dropdown-up' : 'icon-dropdown-down')}></span>
        </Button>
      ) : (
        ''
      )}
    </StyledOverviewTab>
  );
};
